@import "style_utils";

.wrapper {
  a,
  a:hover {
    text-decoration: none;
  }
}

.image {
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  border-radius: $borderRadius;
  height: 0;
  padding-bottom: 100%;

  @include media-sm {
    padding-bottom: 45%;
  }

  @include media-desktop {
    padding-bottom: 56%;
  }

  @include media-xxl {
    padding-bottom: 38%;
  }
}

.text {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 23px;
  text-decoration: none;
  @include media-desktop {
    max-width: 280px;
  }
}

.buttonWrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  padding: 23px 16px;
  @include media-desktop {
    max-width: 280px;
    width: auto;
    position: relative;
    bottom: auto;
    padding: 0 16px;
  }
  .button {
    width: 100%;
    @include media-desktop {
      width: auto;
    }
  }
}
