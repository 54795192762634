@import "style_utils";

$slider-height: 10px;
$background-slider: $mediumSky;
$background-filled-slider: $turquoise;
$background-slider-warning: $lightWarning;
$background-filled-slider-warning: $sunglow;
$thumb-width: 20px;
$thumb-height: 20px;
$thumb-radius: 50%;
$thumb-background: transparent;
$thumb-border: 0;

// Focused thumb
$focused-thumb-width: 36px;
$focused-thumb-height: 36px;
$focused-thumb-background: transparent;

.labelRow {
  display: flex;
  justify-content: space-between;

  .popover {
    align-items: flex-start;
  }
}

.label {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 16px;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
  overflow: hidden;
  padding: 0px 15px;
}

.titleValueWrapper {
  @extend .flexBetween;
  margin-bottom: 20px;
  p {
    margin-bottom: 0;
  }
}

.numberInput {
  width: 101px;

  input {
    text-align: center;
    font-family: "Santander Headline", Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgba($color: #000000, $alpha: 0.77);
  }

  @include media-desktop {
    width: 132px;

    input {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.sliderWrapper {
  position: relative;

  .sliderInputWrapper {
    border-radius: $borderRadius;
  }
}

.slider {
  width: 100%;
  appearance: none;
  background: none;
  cursor: pointer;
  display: flex;
  height: $slider-height;
  border-radius: 6px;
  -webkit-appearance: none;

  &:focus-visible {
    outline: 1px dashed red;
  }

  @include media-ie {
    min-height: 70px;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }

  &::-webkit-slider-thumb {
    width: $thumb-width;
    height: $thumb-height;

    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-radius: $borderRadius;
    background: rgba($color: #000000, $alpha: 0);
  }

  &:focus::-webkit-slider-thumb {
    width: $focused-thumb-width;
    height: $focused-thumb-height;
  }

  &::-webkit-slider-runnable-track {
    background: rgba($color: #000000, $alpha: 0);
    border: none;
    border-radius: $borderRadius;
    -webkit-appearance: none;
    appearance: none;
  }

  &::-moz-range-thumb {
    width: $thumb-width;
    height: $thumb-height;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
    position: relative;
  }

  &:focus::-moz-range-thumb {
    width: $focused-thumb-width;
    height: $focused-thumb-height;
  }

  &::-ms-track {
    background: transparent;
    border: 0;
    border-color: transparent;
    border-radius: $borderRadius;
    border-width: 0;
    color: transparent;
    height: $slider-height;
    margin-top: 10px;
    width: 100%;
  }

  &::-ms-thumb {
    width: $thumb-width;
    height: $thumb-height;

    background: none;
    border-radius: 0;
    border: none;
    transition: none;
  }

  &:focus::-ms-thumb {
    width: $focused-thumb-width;
    height: $focused-thumb-height;
  }

  &::-ms-fill-lower {
    background: $background-filled-slider;
    border-radius: $borderRadius;
  }

  &::-ms-fill-upper {
    background: $background-slider;
    border-radius: $borderRadius;
  }

  &::-ms-tooltip {
    display: none;
  }

  &.warning {
    &::-ms-fill-lower {
      background: $background-filled-slider-warning;
    }

    &::-ms-fill-upper {
      background: $background-slider-warning;
    }
  }

  &.disabledTrack {
    background: $borderGrey;
    pointer-events: none;
  }
}

.sliderThumb {
  width: calc(100% - 15px);
  position: absolute;
  top: 0;

  &.disabledTrack {
    display: none;
  }

  span {
    pointer-events: none;
    z-index: 30;
    position: absolute;
    @extend .flexCenter;

    top: -3px;
    left: -1px;
    background: $background-filled-slider;
    width: $thumb-width;
    height: $thumb-width;
    border-radius: 50%;

    @include media-ie {
      top: 22px;
    }

    &:after {
      content: "";
      position: absolute;
      width: $focused-thumb-width;
      height: $focused-thumb-width;
      background: $background-filled-slider;
      border-radius: 50%;
      opacity: 0;
      @include transition(opacity, 0.2s, ease-in);

      @include media-ie {
        top: -8px;
        left: -8px;
      }
    }
  }
  &.focused {
    span {
      &:after {
        opacity: 0.4;
      }
    }
  }

  &.warning {
    span {
      background: $background-filled-slider-warning;
      &:after {
        background: $background-filled-slider-warning;
      }
    }
  }
}

.labels {
  display: flex;
  margin-top: 17px;
  span {
    color: $doveGrey;
    font-size: 16px;
  }
}

.maxLabel {
  margin-left: auto;
}
