@import "style_utils";

.checkboxWrapper {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  .checkmark, .checkmarkDisabled {
    @extend .flexCenter;
    height: 32px;
    width: 32px;
    border: solid 2px $sinbad;
    border-radius: 5px;
  }
  &.disabled {
    cursor: default;
  }
}

.label {
  margin-left: 10px;
  flex: 1;
  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
  }
  &.disabled {
    color: $boulder;
    opacity: 0.75;
  }
}

.checkboxWrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:focus-visible + .checkmark, .checkmarkDisabled {
    outline: 1px dashed $red;
  }

}

.checkboxWrapper input:checked ~ .checkmarkDisabled {
  border: 1px solid $boulder;
  opacity: 0.75;
}

.checkboxWrapper input:checked ~ .checkmark{
  border: 2px solid $sinbad;
}

.checkmark:after,
.checkmarkDisabled:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxWrapper input:checked ~ .checkmarkDisabled:after,
.checkboxWrapper input:checked ~ .checkmark:after {
  display: block;
}

.checkboxWrapper .checkmark:after {
  width: 8px;
  height: 12px;
  border: solid $jellybean;
  border-width: 0 3px 3px 0;
  @include rotate(45deg);
  @include transition(border, 150ms, ease-out);
}

.checkboxWrapper .checkmarkDisabled:after {
  opacity: 0.75;
  width: 8px;
  height: 12px;
  border: solid $boulder;
  border-width: 0 3px 3px 0;
  @include rotate(45deg);
  @include transition(border, 150ms, ease-out);
}