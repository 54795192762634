@import "style_utils";


.wrapper {
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    .contentWrapper {
      margin-bottom: 20px;
    }

    .accountInfoContainer {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $mediumSky;    
      }

    .accountInfoRow {
        font-size: 16px;
    }

    &.pageContent{
        padding: 30px 30px;
        border-radius: 8px;
    
        .headerContainer {
            margin-bottom: 30px;  
            }
    
        @include media-desktop {
            padding: 30px 50px;
            border-radius: 8px;
            box-shadow: 0px 6px 9px rgba(153, 196, 212, 0.5);
          }
    }

  }

