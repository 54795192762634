@import "style_utils";

.section {
  padding: 30px 0px;
}

.wrapper {
  border-radius: 8px;

  &.bgBlackSqueeze {
    background-color: $blackSqueeze;
    padding: 0 15px;
  }

  > div:not(:last-child) {
    border-bottom: 1px solid $borderGrey;
  }
}

.label {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 16px;
}
