@import "style_utils";

.paymentBreakDown,
.question {
  margin-bottom: 20px;
}

.input {
  margin-top: 20px;
}

.actions {
  @extend .flexColumn;
  @extend .flexEnd;
  margin-top: 18px;

  @include media-desktop {
    flex-direction: row;
    width: 100%;

    & > button:last-child {
      margin-left: 33px;
    }
  }
}

.datePickerWrapper {
  margin-bottom: 20px;
}

.dayPicker {
  z-index: 1;
  position: absolute;
  top: 75px;
  width: 100%;

  opacity: 0;
  visibility: hidden;
  max-height: 0;

  @include transition((opacity, visibility), 0.2s, ease-out);
  &.open {
    opacity: 1;
    visibility: visible;
    max-height: 100%;
    margin-bottom: 24px;
  }
  & > div {
    width: 100%;
  }

  @include media-desktop {
    width: 50%;
  }
}

.dropdownWrapper {
  position: relative;
  .dropdown {
    padding: 12px 0 20px 0;
    width: 100%;

    @include media-desktop {
      width: 50%;
    }
  }
}

.amountLabel {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.amount {
  font-size: 32px;
  line-height: 24px;
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 15px;
}

.paymentInfo {
  padding-left: 26px;
  padding-right: 24px;
  border-left: 2.4px solid $red;
  margin-bottom: 16px;

  .row {
    display: flex;
    flex-direction: column;
    @include media-desktop {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    span {
      display: inline-block;
      font-size: 18px;
      line-height: 42px;
    }

    .label {
      margin-bottom: -11px;
      font-weight: bold;
      @include media-desktop {
        margin: 0;
      }
    }
  }
}

.textContent {
  margin-bottom: 20px;
}

.phoneText {
  margin-bottom: 20px;
  margin-top: 10px;
  p {
    font-size: 13px;
  }
}