@import "style_utils";

.imagesWrapper {
  @extend .flexCenter;
  position: relative;
  line-height: 0;

  img {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 100%;
    @include transition(opacity, 0.2s, ease-out);
  }

  .image {
    opacity: 1;
    position: relative;
  }

  &.active {
    .activeImage {
      opacity: 1;
    }
    .hoverImage,
    .image {
      opacity: 0;
    }
  }

  &.hoverEnabled {
    &:hover,
    &.hover {
      .image,
      .active {
        opacity: 0;
      }
      .hoverImage {
        opacity: 1;
      }
    }
  }
}
