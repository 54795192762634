@import "style_utils";

.progressWrapper {
  width: 100%;
  & > p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
  }

  .progressState {
    margin-bottom: 16px;
  }
}

.progressBar {
  position: relative;
  height: 6px;
  border-radius: 6px;
  background-color: $mediumSky;
  flex: 1;

  & > div {
    position: absolute;
    width: 0;
    height: 100%;
    left: 0;
    border-radius: 15px;
    @include transition(width, 0.2s, ease-in);
    background-color: $surfieGreen;
  }
}

.completedPercentage {
  display: block;
  font-family: "Santander Headline", Arial, sans-serif;
  font-size: 16px;
  line-height: 23px;
  font-weight: bold;
  color: $surfieGreen;
  margin-top: 16px;
  text-align: right;
}
