@import "style_utils";

.pageWrapper {
  .loginHeader {
    height: 10vh;
    border-top: $red solid 2px;
    border-bottom: $alto solid 0.5px;
    padding: 20px 25px;

    .loginLogotype {
      width: 144px;
      height: 44px;
    }

    @include media-desktop {
      padding: 20px 100px;

      .loginLogotype {
        width: 314px;
        height: 25px;
      }
    }
  }

  .wrapper {
    display: flex;
    justify-content: center;
    height: 90vh;
    background-color: $backgroundPrimary;

    .content {
      padding-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .notFoundImage {
        width: 50%;
      }
    }
  }
}
