@import "style_utils";

.hamburger {
  @include removeNativeButtonStyling;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 25px;
  height: 100%;
  display: flex;
  align-items: center;
  &::after {
    content: "";
    outline: 1px dashed $red;
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% + 10px);
    height: 100%;
    border-radius: 10px;
    display: none;
    @include media-desktop {
      top: 2px;
      left: -8px;
      width: calc(100% + 16px);
    }
  }

  &:focus-visible {
    outline: none;
    &::after {
      display: block;
    }
  }
}

.hamburgerIcon {
  position: relative;
  width: 20px;
  height: 1.5px;
  background: $tundora;
  right: -8px;
  @include transition(background, 0.2s, ease-out);

  &:before,
  &:after {
    @include transition(transform, 0.2s, ease-out);
  }

  &:before {
    content: "";
    position: absolute;
    background: $tundora;
    height: 1.5px;
    width: 100%;
    top: -8px;
    right: 0;
  }

  &:after {
    content: "";
    position: absolute;
    background: $tundora;
    height: 1.5px;
    width: 100%;
    top: 8px;
    right: 0;
  }

  &.open {
    background: transparent;

    &:before {
      @include transform(translate3d(0, 8px, 0) rotate(45deg));
    }

    &:after {
      @include transform(translate3d(0, -8px, 0) rotate(-45deg));
    }
  }
}

.nav {
  position: absolute;
  left: 0;
  top: $headerHeight_lg;
  width: 100%;
  height: calc(100vh - #{$headerHeight_lg});
  padding: 30px 50px 50px 50px;
  background: $white;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  @include transition((opacity, visibility), 0.2s, ease-out);

  &.open {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  a,
  button {
    color: $black;
    text-decoration: none;
    font-size: 20px;
    line-height: 55px;
    padding: 0;
    margin: 0;
    &:hover {
      font-weight: bold;
      color: $red;
      text-decoration: none;
    }
  }
}
