@import "style_utils";

.wrapper {
  background-color: $white;
  border-radius: 8px;
  padding: 20px;
}

.form {
  position: relative;
  height: 100%;
}

.progressBar {
  width: 100%;

  & > div {
    height: 100%;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.footer {
  @extend .flexBetween;
  padding-top: 30px;
  width: 100%;
  margin-left: auto;
  flex-direction: column;

  @include media-desktop {
    flex-direction: row;
  }

  & > .alignRight {
    margin-left: auto;
    margin-top: 24px;

    @include media-desktop {
      margin-top: 0;
    }
  }
}

.field {
  &.editable {
    padding-bottom: 30px;
  }

  :global {
    .DayPicker {
      width: 100%;

      @include media-desktop {
        width: 400px;
      }
    }
  }
}
