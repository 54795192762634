@import "style_utils";

.summary {
  padding: 16px;
  background-color: rgba($color: $limeGreen, $alpha: 0.3);
  border-radius: 8px;
  margin-bottom: 47px;
  margin-top: 12px;

  &.noMarginBottom {
    margin-bottom: 0;
  }

  &.bgSunglow {
    background-color: rgba($color: $sunglow, $alpha: 0.3);
  }

  &.bgLightSuccess {
    background-color: $lightSuccess;
  }

  .summaryColGroup {
    flex: 1;
    padding-bottom: 8px;
  }

  .summaryCol,
  .summarySubCol {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .summarySubCol {
    font-size: 14px;

    @include media-desktop {
      font-size: 16px;
    }
  }

  .summaryRow {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;

    @include media-desktop {
      flex-direction: row;

      & > .summaryColGroup {
        &:first-child {
          padding-right: 24px;
        }

        &:last-child {
          padding-left: 24px;
        }
      }

      &.singleCol {
        width: 50%;

        & > .summaryColGroup {
          padding-left: 0;
        }
      }
    }
  }
}
