@import "style_utils";

.wrapper {
  @extend .horizontalGrid;
  padding-top: 12px;
  padding-bottom: 0;
}

.link {
  position: relative;
  color: $red;
  white-space: nowrap;
  font-size: 14px;
  line-height: 30px;
  &:not(:last-child) {
    margin-right: 24px;
    &:after {
      pointer-events: none;
      content: "";
      position: absolute;
      right: -12px;
      top: 50%;
      width: 8px;
      height: 8px;
      border-top: 1px solid;
      border-right: 1px solid;
      border-color: $boulder;
      @include transform(rotate(45deg) translate3d(0, -50%, 0));
    }
  }
  &:last-child {
    text-decoration: none;
    color: $boulder;
  }
}
