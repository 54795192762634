@import "style_utils";

$borderWidth: 3px;

.dropzone {
  display: flex;
  @extend .flexCenter;
  width: 100%;
  border-color: $sinbad;
  border-width: $borderWidth;
  border-style: dashed;
  border-radius: 8px;
  min-height: 100px + $borderWidth * 2;
  @include transition((background-color), 0.1s, linear);

  &.hasAttachment {
    border-radius: 8px 8px 0 0;
  }

  &.focus {
    background-color: $blackSqueeze;
  }

  p {
    width: 100%;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0;
    text-align: center;

    span {
      color: $red;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @include media-tablet {
    line-height: 32px;

    p {
      font-size: 20px;
    }
  }
}

.attachments {
  @extend .flexCol;
  position: relative;
  padding: 16px;
  gap: 8px;
  background-color: $catskillWhite;
  border-radius: 0 0 8px 8px;
  .attachment {
    position: relative;
    @extend .flexBetween;
    background-color: $white;
    padding: 12px;
    border-radius: 8px;
    gap: 8px;

    & > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      margin-bottom: 0;
      flex: 1;
    }

    .attachmentDetails {
      display: flex;
      gap: 8px;
      flex: initial;
      .fileExtension {
        @extend .flexCenter;
        padding: 0 8px;
        border-radius: 8px;
        font-size: 12px;
        background-color: $catskillWhite;
      }
    }

    .close {
      @extend .flexCenter;
      cursor: pointer;
      width: 24px;
      height: 24px;
      border-radius: 20px;
      background-color: $mediumSky;
    }

    @include media-tablet {
      padding: 8px 16px;
      .attachmentDetails {
        flex: 0.5;
      }
    }
  }

  @include media-tablet {
    padding: 24px;
  }
}

.attachmentRulesText {
  font-size: 12px;
  display: block;

  &:not(:last-child) {
    margin-top: 12px;
  }

  @include media-tablet {
    font-size: 16px;

    &:not(:last-child) {
      margin-top: 16px;
    }
  }
}

.spinner {
  position: relative;
  width: 24px;
  height: 24px;
}
