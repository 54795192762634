@import "style_utils";

$borderWidth: 2px;

.wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
}

.labelRow {
  display: flex;
  justify-content: space-between;

  .popover {
    align-items: flex-start;
  }
}

.label {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 12px;
  @include media-desktop {
    margin-bottom: 16px;
  }
}

.dropdownWrapper {
  position: relative;
  width: 100%;
}

.header {
  width: 100%;
  height: 100%;
  height: $buttonHeight_lg;
  @extend .flexBetween;
  padding: 0 16px;
  cursor: pointer;
  font-size: 16px;
  border: $borderWidth solid $sinbad;
  border-radius: $borderRadius;

  &.borderless {
    border: none;

    &.open {
      border: $borderWidth solid $sinbad;
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.open {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  @include media-desktop {
    font-size: 18px;
  }

  .expandIcon {
    width: 14px;
    @include rotate(90deg);
    @include transition((transform), 0.2s, ease-out);

    &.smallChevron {
      width: 10px;
    }
  }

  &.open {
    .expandIcon {
      @include rotate(270deg);
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    padding-right: 8px;

    @include media-desktop {
      font-size: 18px;
    }

    &.black {
      color: $black;
    }
  }
  &.disabled {
    .title {
      opacity: 0.3;
    }
    cursor: auto;
  }
  &.fontRegular {
    font-weight: 400;
  }

  .suffix {
    margin-right: 16px;
  }
}

.dropdownInnerHeader {
  position: sticky;
  top: 0;
  border-top: 1px solid transparent;
  background: $white;
}

.headerSeparator {
  height: 0;
  width: calc(100% - 15px);
  background: none;
  border-top: 0;
  border-bottom: 1px solid $borderGrey;
  margin-top: 6px;
  margin-bottom: 16px;
}

.innerLabel {
  font-size: 14px;
  font-weight: bold;
}

.dropdownListWrapper {
  position: absolute;
  width: 100%;
  overflow: hidden;
  z-index: 20;
  margin: 0;
  background-color: rgba($color: $white, $alpha: 0);

  .dropdownList {
    width: calc(100% - 9px);
    overflow: auto;
    max-height: 0;
    padding: 0 16px;
    ul {
      list-style: none;
      padding: 0;
    }
    // Scrollbar
    scrollbar-width: thin;
    scrollbar-color: $blackSqueeze $cornflowerBlue;

    &::-webkit-scrollbar {
      width: 8px;
      margin-right: 10px;
    }
    &::-webkit-scrollbar-track {
      background: $cornflowerBlue;
      border-radius: 11px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $blackSqueeze;
      border-radius: 11px;
    }

    &::-webkit-scrollbar-button:start:decrement,
    &::-webkit-scrollbar-button:end:increment {
      height: 15px;
      display: block;
      background-color: transparent;
    }
  }

  &.open {
    background-color: rgba($color: $white, $alpha: 1);

    border: $borderWidth solid $sinbad;
    border-radius: $borderRadius;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    .dropdownList {
      max-height: 300px;
    }
  }

  .listItem {
    @extend .flexAlignCenter;
    min-height: $buttonHeight_lg;
    padding: 10px 0;
    width: 100%;
    cursor: pointer;
    font-size: 16px;

    @include media-desktop {
      font-size: 18px;
    }

    &:hover {
      color: $jellybean;
    }

    &.active {
      color: $jellybean;
      font-weight: bold;
    }

    &.suffixWrapper {
      display: flex;
      justify-content: space-between;

      .suffix {
        margin-right: 16px;
      }
    }
  }
}

.mobileSuffix {
  @extend .flexBetween;
  margin-top: 12px;
}

.itemValue {
  @extend .flexAlignCenter;

  img {
    margin-right: 12px;
  }
}
