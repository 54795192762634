@import "style_utils";

.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9999999; // Forced to set this z-index due to sandrine chatbot button
  .wrapper {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    width: calc(100% - 30px);
    position: relative;
    max-height: 90vh;
    background-color: $white;
    border-radius: $borderRadius;
    overflow: hidden;
    .headerWrapper {
      width: 100%;
      background-color: $red;
      padding: 8px 34px;
      color: $white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .modalTitle {
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0px;
        color: $white;
      }
    }
    .scrollWrapper {
      position: relative;
      padding: 16px 32px;
      overflow-y: auto;

      @include media-desktop {
        padding: 30px 50px;
      }

      &.noPadding {
        padding: 0;
      }
    }
  }
}
.closeWrapper {
  position: relative;
  &:focus-visible {
    outline: 1px dashed $black;
  }
  img {
    position: relative !important;
  }
}
