.flex {
  display: flex;
}

.inlineFlex {
  display: inline-flex;
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}

.alignCenter {
  align-items: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexFixCenterCol,
.flexFixedCenterCol {
  @extend .flexCenter;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexCol,
.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexCenterCol {
  @extend .flexCol;
  justify-content: center;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse;
}

.flex1 {
  flex: 1;
}

.flexEnd {
  justify-content: flex-end;
}

.flexStart {
  align-items: flex-start;
}

.flexWrap {
  display: flex;
  flex-wrap: wrap;
}
