@import "style_utils";

.cardWrapper {
  position: relative;
  margin-bottom: 24px;
  width: 100%;
  max-width: 328px;

  &.wrapperLarge {
    width: calc(100% + 12px);
    max-width: 340px;
    left: -6px;
  }

  @include media-desktop {
    margin-bottom: 0;
    width: 263px;

    &.wrapperLarge {
      max-width: 287px;
      width: calc(100% + 24px);
      left: -12px;
    }
  }
}

.cardSliderItem {
  margin: 0 6px;

  @include media-desktop {
    margin: 0 12px;
  }
}

.cardItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 19px;
  border-radius: 10px;
  // Based on card design width & height
  aspect-ratio: 1.59393939;

  &.red {
    background-color: $red;
  }

  &.tundora {
    background-color: $tundora;
  }

  .cardContent {
    p {
      margin-bottom: 0;
      color: $white;
    }

    & > div {
      margin-top: 8px;
    }

    .cardDetails {
      @extend .flexBetween;
    }

    .cardState {
      font-size: 14px;
      font-weight: bold;
      flex: 0;
      margin-left: auto;
    }

    .cardNumber {
      font-size: 20px;
      line-height: 32px;

      @include media-xxs {
        font-size: 24px;
        line-height: 36px;
      }

      @include media-desktop {
        font-size: 20px;
        line-height: 32px;
      }
    }

    .expiry,
    .holder {
      font-size: 12px;
      line-height: 20px;

      @include media-desktop {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &.disabled {
    &.red {
      .cardContent {
        p:not(.cardState) {
          color: $borderGrey;
        }
      }
    }

    &.tundora {
      .cardContent {
        p:not(.cardState) {
          color: $doveGrey;
        }
      }
    }
  }

  @include media-xxs {
    padding: 24px;
  }

  @include media-desktop {
    padding: 19px;
  }
}

:global {
  .card-slider {
    position: relative;

    .custom-swiper-pagination {
      text-align: center;
      line-height: 0;
      margin-top: 24px;

      .swiper-pagination-bullet {
        position: relative;
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: $alto;
        border-radius: 50%;
        margin: 0 8px;
        cursor: pointer;
        @include transition(background-color, 0.3s, ease-in);

        &.swiper-pagination-bullet-active {
          background-color: $black;
        }
      }
    }
  }
}
