$grid-breakpoints: (
  xs: 0,
  lg: 992px,
);

@mixin add_mapped_styles($styles) {
  @each $name, $style in $styles {
    #{$name} {
      @each $attr, $value in $style {
        #{$attr}: #{$value};
      }
    }
  }
}

@mixin add_media_styles($media_styles) {
  @each $key, $styles in $media_styles {
    @if $key == "xs" {
      @include add_mapped_styles($styles);
    } @else {
      $bp: map-get($grid-breakpoints, $key);
      @media (min-width: $bp) {
        @include add_mapped_styles($styles);
      }
    }
  }
}

@function ftransition($properties, $time, $curve) {
  $result: "";
  @each $property in $properties {
    $i: index($properties, $property);
    @if $i > 1 {
      $result: $result + ", ";
    }
    $result: $result + $property + " " + $time + " " + $curve;
  }
  @return $result;
}

@mixin transition($properties, $time, $curve) {
  $t: ftransition($properties, $time, $curve);
  -webkit-transition: #{$t};
  -moz-transition: #{$t};
  -ms-transition: #{$t};
  -o-transition: #{$t};
  transition: #{$t};
}

@mixin transition_delay($delay) {
  -moz-transition-delay: $delay;
  -ms-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

/*
  Usage:

  @include placeholder {
    font-style:italic;
    color: white;
    font-weight:100;
}

*/
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin line_clamp($numLines: 1, $lineHeight: 1.412) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
  max-height: $numLines * $lineHeight + unquote("em");
}

@mixin transform($transform) {
  -ms-transform: $transform;
  -moz-transform: $transform;
  -webkit-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin rotate($deg: 90deg) {
  -ms-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -webkit-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin removeNativeButtonStyling {
  background: none;
  color: inherit;
  border: none;
  outline: inherit;
  padding-left: 0;
}

@mixin outlineHoverVisible {
  &:after {
    content: "";
    background-color: transparent;
    outline: 1px dashed $red;
    position: absolute;
    display: none;
    border-radius: 8px;
    left: 0;
    top: 1%;
    width: 100%;
    height: 95%;
  }

  &:focus-visible:after {
    display: block;
  }
}

@mixin linkHoverEffect {
  &:hover {
    color: $darkRed;
    @include transition("color", 0.5s, ease-in-out);
  }
}

@mixin buttonHoverEffect {
  &:hover {
    background-color: $darkRed;
    @include transition("background-color", 0.5s, ease-in-out);
    color: $white;
  }
}

/* Media query utils */

@mixin media-xxs {
  @media (min-width: 350px) {
    @content;
  }
}
@mixin media-xs {
  @media (min-width: 400px) {
    @content;
  }
}
@mixin media-sm {
  @media (min-width: 540px) {
    @content;
  }
}
@mixin media-tablet {
  @media (min-width: 720px) {
    @content;
  }
}
@mixin media-desktop {
  @media (min-width: 992px) {
    @content;
  }
}
@mixin media-xl {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin media-xxl {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin media-ipad-pro {
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    @content;
  }
}

@mixin media-ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

/* Keyframe animations */

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes delayHide {
  from {
    visibility: visible;
  }
  to {
    visibility: hidden;
  }
}

@keyframes delayShowOverflow {
  from {
    overflow: hidden;
  }
  to {
    overflow: visible;
  }
}
