@import "style_utils";

.file {
  display: flex;
  @extend .flexAlignCenter;
  cursor: pointer;
  position: relative;
  @include removeNativeButtonStyling;
  &:focus-visible {
    outline: 1px dashed $red;
    border-radius: 5px;
  }

  &:hover {
    span {
      color: $darkRed;
      @include transition("color", 0.5s, ease-in-out);
    }
  }

  @include media-desktop {
    &:not(:last-child) {
      padding-bottom: 5px;
    }
  }

  span {
    color: $red;
    font-size: 16px;
    &:hover {
      font-weight: bold;
      text-decoration: underline;
    }

    @include media-desktop {
      font-size: 18px;
    }
  }
}

.documentLoader {
  display: block;
  min-height: 20px;
  margin-top: 3px;
  margin-right: 33px;

  .spinner {
    width: 16px;
    height: 16px;
    @include media-desktop {
      left: 11px;
      width: 20px;
      height: 20px;
    }
  }
}
