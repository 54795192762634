@import "style_utils";

.wrapper {
    @extend .flexBetween;
    flex-direction: column;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 2px solid $blackSqueeze;

    img {
      padding: 16px 0;
      @include rotate(90deg);
    }

    .currentValue,
    .updatedValue {
      @extend .flexAlignCenter;
      width: 100%;
      border-radius: 8px;
      padding: 4px 16px;
      min-height: 52px;
    }

    .currentValue {
      background-color: $borderGrey;
    }

    .updatedValue {
      border: 2px solid $darkSky;
    }

    @include media-desktop {
      flex-direction: row;

      img {
        padding: 0 16px;
        @include rotate(0);
      }
    }
  }