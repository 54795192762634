@import "style_utils";

.wrapper {
  border-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  p {
    margin-bottom: 0;
  }
}
