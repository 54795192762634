.wrapper {
  position: relative;
  display: grid;
  flex-direction: column;
  .stageContainer {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: white;
    animation: both;
    transition: opacity 200ms;
    &.visible {
        visibility: visible;
        opacity: 1;
        z-index: 10;
    }

    > * {
        margin-top: 20px;
      }

    .notification {
        width: 100%;
    }
    .buttonsWrapper {
        display: flex;
        justify-content: flex-end;
        > :last-child {
            margin-left: 30px;
        }
    }
  }
}

@keyframes expand {
  from {
    height: 0;
  }
  to {
    height: auto;
  }
}
