@import "../variables/colors";

// Santander Text

@font-face {
  font-family: "Santander Text";
  src: url("netbank-shared/src/fonts/SantanderText-Bold.eot");
  src: url("netbank-shared/src/fonts/SantanderText-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("netbank-shared/src/fonts/SantanderText-Bold.woff2") format("woff2"),
    url("netbank-shared/src/fonts/SantanderText-Bold.woff") format("woff"),
    url("netbank-shared/src/fonts/SantanderText-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Santander Text";
  src: url("netbank-shared/src/fonts/SantanderText-Regular.eot");
  src: url("netbank-shared/src/fonts/SantanderText-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("netbank-shared/src/fonts/SantanderText-Regular.woff2") format("woff2"),
    url("netbank-shared/src/fonts/SantanderText-Regular.woff") format("woff"),
    url("netbank-shared/src/fonts/SantanderText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Santander Headline

@font-face {
  font-family: "Santander Headline";
  src: url("netbank-shared/src/fonts/SantanderHeadline-Bold.eot");
  src: url("netbank-shared/src/fonts/SantanderHeadline-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("netbank-shared/src/fonts/SantanderHeadline-Bold.woff2") format("woff2"),
    url("netbank-shared/src/fonts/SantanderHeadline-Bold.woff") format("woff"),
    url("netbank-shared/src/fonts/SantanderHeadline-Bold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Santander Headline";
  src: url("netbank-shared/src/fonts/SantanderHeadline-Regular.eot");
  src: url("netbank-shared/src/fonts/SantanderHeadline-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("netbank-shared/src/fonts/SantanderHeadline-Regular.woff2")
      format("woff2"),
    url("netbank-shared/src/fonts/SantanderHeadline-Regular.woff")
      format("woff"),
    url("netbank-shared/src/fonts/SantanderHeadline-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// for cookie consent styling to look like open pages
html {
  font-size: calc(17px + 7 * ((100vw - 420px) / 860));
  line-height: calc(27px + 9 * ((100vw - 420px) / 860));
  font-weight: 400;
  text-rendering: geometricPrecision;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --font-family-default: "Santander Text", Arial, sans-serif;
  --font-family-headline: "Santander Headline", Arial, sans-serif;
  @media screen and (min-width: 1280px) {
    font-size: 24px;
  }
}

p {
  font: inherit;
  margin: 0;
  border: 0;
  list-style: none;
  padding: 0;
}

// cookie consent styling revert
:global {
  #root {
    font-size: initial;
    line-height: initial;
  }
}

html,
body,
input,
button,
textarea,
pre {
  font-family: "Santander Text", Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 12px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  color: $black;
  font-family: "Santander Headline", Arial, sans-serif;
  font-weight: bold;

  margin-top: 0;
  margin-bottom: 12px;
}

p,
label {
  margin: 0;
  margin-bottom: 12px;
  color: $black;
}

a {
  color: $red;
  text-decoration: underline;
  font-weight: normal;
}

a:hover {
  color: $darkRed;
  @include transition(color, 0.5s, ease-in);
}

a:focus-visible {
  outline: 1px dashed $red;
  border-radius: 5px;
}

$media_styles: (
  xs: (
    "h1,.h1": (
      font-size: 28px,
      line-height: 34px,
    ),
    "h2,.h2": (
      font-size: 24px,
      line-height: 29px,
    ),
    "h3,.h3": (
      font-size: 16px,
      line-height: 24px,
    ),
    "p": (
      font-size: 16px,
      line-height: 24px,
    ),
    "li": (
      font-size: 16px,
      line-height: 24px,
    ),
    "th, td": (
      font-size: 18px,
      line-height: 36px,
    ),
  ),
  lg: (
    "h1,.h1": (
      font-size: 36px,
      line-height: 43px,
    ),
    "h2,.h2": (
      font-size: 32px,
      line-height: 38px,
    ),
    "h3,.h3": (
      font-size: 18px,
      line-height: 27px,
    ),
    "p": (
      font-size: 18px,
      line-height: 27px,
    ),
    "li": (
      font-size: 18px,
      line-height: 27px,
    ),
  ),
);

@include add_media_styles($media_styles);
