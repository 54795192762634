@import "style_utils";

.wrapper {
  width: 100%;
  padding: 16px;
  padding-bottom: 24px;
  border-radius: 8px;
  border: 2px solid $mediumSky;

  &.selected {
    border-radius: 8px 8px 0 0;
  }

  .yearWrapper {
    @extend .flexBetween;
    padding: 8px 0px 16px 0px;

    .prev,
    .next {
      cursor: pointer;
      @include transition((color, opacity), 0.1s, linear);

      &.disabled {
        color: $boulder;
        cursor: default;
        pointer-events: none;
        opacity: 0.2;
      }
    }

    .prev {
      @include rotate(180deg);
    }
  }

  .monthsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 16px;

    .month {
      padding: 12px;
      text-align: center;
      cursor: pointer;
      @include transition((background-color, font-weight, border-radius, color, opacity), 0.15s, linear);

      &:hover {
        background-color: $blackSqueeze;
        border-radius: 50px;
      }

      &.active {
        font-weight: bold;
        background-color: $mediumSky;
        border-radius: 50px;

        &:has(+ .active) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        & + .active {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &.disabled {
        color: $boulder;
        cursor: default;
        pointer-events: none;
        opacity: 0.2;
      }

      &.locked {
        cursor: default;
        pointer-events: none;
        background-color: $borderGrey;
        color: $boulder;
      }
    }

    @include media-desktop {
      row-gap: 40px;
    }
  }
}

.summary {
  position: relative;
  @extend .flexCol;
  padding: 16px;
  gap: 8px;
  background-color: $catskillWhite;
  border-radius: 0 0 8px 8px;

  & > div {
    position: relative;
    @extend .flexBetween;
    background-color: $white;
    padding: 12px;
    border-radius: 8px;

    p {
      margin-bottom: 0;
    }

    .close {
      @extend .flexCenter;
      cursor: pointer;
      width: 24px;
      height: 24px;
      border-radius: 20px;
      background-color: $mediumSky;
    }
  }

  @include media-desktop {
    padding: 24px;

    & > div {
      padding: 8px 16px;
    }
  }
}
