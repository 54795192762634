@import "style_utils";

.wrapper {
  @extend .flexAlignCenter;
  @include removeNativeButtonStyling;
  
  cursor: pointer;
  position: relative;
  height: 100%;

  img {
    margin-right: 9px;
  }
}
