@import "style_utils";

$borderWidth: 2px;

.wrapper {
  display: inline-block;
  position: relative;
  width: 100%;

  .subtext p {
    font-size: 16px;
  }
}

.labelRow {
  display: flex;
  justify-content: space-between;

  .popover {
    align-items: flex-start;
  }
}

.dropdownWrapper {
  position: relative;
  width: 100%;
}

.header {
  width: 100%;
  height: 100%;
  height: $buttonHeight_lg;
  @extend .flexBetween;
  padding: 0 16px;
  cursor: pointer;
  font-size: 16px;
  border: $borderWidth solid $sinbad;
  border-radius: $borderRadius;

  &.open {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.disabled {
    > * {
      opacity: 0.15;
    }
  }

  @include media-desktop {
    font-size: 18px;
  }

  .expandIcon {
    width: 14px;
    @include rotate(90deg);
    @include transition((transform), 0.2s, ease-out);
  }

  &.open {
    .expandIcon {
      @include rotate(270deg);
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;

    @include media-desktop {
      font-size: 18px;
    }

    &.black {
      color: $black;
    }
  }
  &.disabled {
    .title {
      opacity: 0.3;
    }
    cursor: auto;
  }
  &.fontRegular {
    font-weight: 400;
  }

  .suffix {
    margin-right: 16px;
  }
}

.dropdownListWrapper {
  position: absolute;
  width: 100%;
  overflow: hidden;
  z-index: 10;
  margin: 0;
  background-color: rgba($color: $white, $alpha: 0);

  .dropdownList {
    width: calc(100% - 9px);
    overflow: auto;
    max-height: 0;
    padding: 0 16px;
    ul {
      list-style: none;
      padding: 0;
    }
    // Scrollbar
    scrollbar-width: thin;
    scrollbar-color: $blackSqueeze $cornflowerBlue;

    &::-webkit-scrollbar {
      width: 8px;
      margin-right: 10px;
    }
    &::-webkit-scrollbar-track {
      background: $cornflowerBlue;
      border-radius: 11px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $blackSqueeze;
      border-radius: 11px;
    }

    &::-webkit-scrollbar-button:start:decrement,
    &::-webkit-scrollbar-button:end:increment {
      height: 15px;
      display: block;
      background-color: transparent;
    }
  }

  &.open {
    background-color: rgba($color: $white, $alpha: 1);

    border: $borderWidth solid $sinbad;
    border-radius: $borderRadius;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    .dropdownList {
      max-height: 300px;
    }
  }

  .listHeader {
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    border-top: 1px solid $borderGrey;
    &.title {
      padding-top: 16px;
      margin-top: 6px;
    }
  }

  .listItem {
    @extend .flexAlignCenter;
    min-height: $buttonHeight_lg;
    width: 100%;
    cursor: pointer;
    font-size: 16px;

    &.lastItem {
      margin-bottom: 10px;
    }

    @include media-desktop {
      font-size: 18px;
    }

    &.disabled {
      color: $boulder;
      cursor: default;
    }

    &:hover {
      color: $jellybean;
      
      &.disabled {
        color: $boulder;
      }
    }

    &.active {
      color: $jellybean;
      font-weight: bold;
    }

    &.suffixWrapper {
      display: flex;
      justify-content: space-between;

      .suffix {
        margin-right: 16px;
      }
    }
  }
}

.mobileSuffix {
  @extend .flexBetween;
  margin-top: 12px;
}

.itemValue {
  @extend .flexAlignCenter;

  img {
    margin-right: 12px;
  }
}

.disabledText {
  font-size: 12px;
  line-height: 20px;
}

.disabledSuffix {
  align-self: flex-start;
}

.disabledIcon {
  align-self: flex-start;
  opacity: 0.7;
}

.itemValueWrapper {
  display: flex;

  img {
    margin-right: 12px;
  }
}
