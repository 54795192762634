@import "style_utils";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: $white;
    border-radius: 8px;
    padding: 30px 30px;
  
    @include media-desktop {
      padding: 30px 50px;
      border-radius: 8px;
      box-shadow: 0px 6px 9px rgba(153, 196, 212, 0.5);
    }
}

.accountInfoContainer {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $mediumSky;    
}

.buttonContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  .button{
    margin-top: 10px;
    @include media-desktop {
      margin-top: 0;
      margin-right: 20px;
    }
  }

  @include media-desktop {
    flex-direction: row;
  }
}

