@import "style_utils";


.topContent {
  margin-bottom: 26px;
}

.transferAccountDropdown {
  margin-bottom: 22px;
}

.actions {
  @extend .flexColumn;
  @extend .flexEnd;
  margin-top: 18px;

  button {
    margin-bottom: 18px;
  }

  @include media-desktop {
    flex-direction: row;
    width: 100%;

    & > button:last-child {
      margin-left: 33px;
    }
  }
}

.buttonContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  .button{
    margin-top: 10px;
    @include media-desktop {
      margin-top: 0;
      margin-right: 20px;
    }
  }

  @include media-desktop {
    flex-direction: row;
  }
}

.receiverSuccess {
  @extend .flex;
  height: $buttonHeight_sm;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 0 16px;
  background-color: $catskillWhite;
  white-space: nowrap;

  p {
    @extend .flexAlignCenter;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    display: block;
    padding-left: 10px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include media-desktop {
    height: $buttonHeight_lg;
  }
}
