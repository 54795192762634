@import "style_utils";

.wrapper {
  @extend .flexCol;
  height: 100vh;

  .image {
    height: 0;
    padding-bottom: 61.33%;
    overflow: hidden;
    background-size: cover;
    width: 100%;

    @include media-desktop {
      &::after {
        content: "";
        background-color: $white;
        position: absolute;
        right: 0;
        width: 80px;
        height: 85%;
      }
    }
  }

  .contentWrapper {
    width: 100%;
    text-align: center;
    padding: 27px 32px;

    h1 {
      font-size: 28px;
      line-height: 34px;
    }

    .subHeader {
      display: block;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $alto;
    }

    .termsText {
      text-align: left;
      padding-bottom: 30px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }

  @include media-desktop {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh - 82px);
    overflow: hidden;

    .imageWrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .image {
      position: relative;
      padding-bottom: 0;
      overflow: hidden;
      background-size: cover;
      background-position: left top;
      width: 58vw;
      height: 100%;
    }

    .contentWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 42vw;
      min-width: 380px;
      text-align: left;
      padding: 0;
      padding-right: 10px;
      padding-bottom: 10px;
      overflow: auto;
      max-height: 100%;

      & > * {
        width: 400px;
      }

      h1 {
        font-size: 48px;
        line-height: 56px;
      }

      .subHeader {
        font-size: 28px;
        line-height: 34px;
        padding-bottom: 28px;
        margin-bottom: 0;
        border-bottom: 0;
      }

      .termsText {
        padding-bottom: 50px;
      }

      p {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

.flexContainer {
  display: block;

  @include media-desktop {
    display: flex;

    & > div {
      flex: 1;
    }
  }
}
