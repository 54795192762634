@import "style_utils";

.wrapper {
  text-align: center;
  border-top: 1px solid $botticelli;
  width: 100%;
  overflow: hidden;
  > div {
    padding: 0px 28px;
  }

  &.bgWhite {
    border-color: $alto;
  }
  .upperFooterWrapper {
    background-color: $backgroundPrimary;

    &.bgWhite {
      background-color: $white;
    }
    width: 100%;
    .upperFooter {
      max-width: 1300px;
      @extend .flexColumn;
      flex-wrap: wrap;
      align-items: center;
      padding: 58px 48px 40px 48px;
      .customerService {
        max-width: 208px;
        @extend .flexColumn;
        align-items: center;
        .customerServiceTitle {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: 0px;
          margin-bottom: 15px;
        }
        .customerServiceContent {
          margin-bottom: 40px;
        }
        .contactButton {
          width: fit-content;
          border-radius: $borderRadius;
          color: $white;
          background-color: $red;
          text-decoration: none;
          padding: 12px 32px;
          text-align: center;
          margin-top: 5px;
          font-size: 16px;
          @include buttonHoverEffect;
        }
      }
      .columnsWrapper {
        @extend .flexColumn;
        flex: 1;
        justify-content: space-between;
        .columnWrapper {
          margin: 18px 0;
          .contentWrapper {
            white-space: nowrap;
            .columnTitle {
              font-size: 18px;
              font-weight: 600;
              line-height: 26px;
              letter-spacing: 0px;
              margin-bottom: 5px;

              @include media-tablet {
                min-height: 25px;
              }
            }
            .column {
              @extend .flexColumn;
              a {
                font-size: 16px;
                font-weight: 400;
                line-height: 37px;
                letter-spacing: 0px;
                color: $black;
                text-decoration: none;
                &:hover {
                  color: $red;
                }
              }
            }
          }
        }
      }
    }
  }
  .lowerFooterWrapper {
    background-color: $backgroundPrimary;

    &.bgWhite {
      background-color: $white;
    }

    .lowerFooter {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      padding: 0px 40px 98px 40px;
      max-width: 1400px;
      align-items: center;

      font-size: 16px;
      font-weight: 400;
      line-height: 17px;
      & > * {
        &:not(.socialMedia) {
          margin-top: 24px;
        }
        &:not(.organisationWrapper) {
          margin-bottom: 24px;
        }
      }

      .mobileIllustration {
        width: 100%;
        max-width: 110px;
        margin-left: auto;
        margin-right: auto;
      }
      .organisationWrapper {
        @extend .flexColumn;
        .policy {
          @extend .flexRow;
          @extend .flexCenter;
          margin-top: 16px;
          a {
            text-decoration: none;
            color: $black;
            &:not(:first-child) {
              margin-left: 8px;
            }
          }
        }
      }
      .socialMedia {
        width: 100%;
        @extend .flexRow;
        justify-content: center;
        a:not(:first-child) {
          margin-left: 20px;
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .desktopLg3ColIllustration {
    object-position: bottom;
    object-fit: contain;
    margin-bottom: -78px;
    width: 104px;
    display: none;
  }
  .desktopLgIllustration {
    display: none;
  }
  .desktopMdIllustration {
    display: none;
    margin-left: 56px;
    padding-bottom: 50px;
    width: calc(100% - 260px);
    min-width: 150px;
    max-width: 300px;
  }
}

@include media-tablet {
  .wrapper {
    text-align: left;
    .upperFooterWrapper {
      .upperFooter {
        align-items: initial;
        padding: 64px 0 70px 0;
        flex-direction: row;
        max-width: 750px;
        .customerService {
          align-items: flex-start;
          .customerServiceTitle {
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 28px;
          }
        }
        .columnsWrapper {
          flex-direction: row;
          .columnWrapper {
            margin-top: 0;
            &:not(:first-child) {
              padding-left: 16px;
              margin-top: 0;
            }
            .column {
              .columnTitle {
                font-size: 16px;
                line-height: 23px;
                margin-bottom: 24px;
              }
            }
          }
        }
      }
    }
    .lowerFooterWrapper {
      background-color: $white;
      .lowerFooter {
        flex-direction: row;
        padding: 24px 0;
        max-width: 750px;
        & > * {
          &:not(.socialMedia) {
            margin-top: 0;
          }
          &:not(.organisationWrapper) {
            margin-bottom: 0;
          }
        }
        .organisationWrapper {
          flex-direction: row;
          justify-content: space-between;
          flex: 1;
          .policy {
            margin-top: 0;
            a :not(:first-child) {
              margin-left: 8px;
            }
          }
        }
        .socialMedia {
          margin-left: 28px;
          flex: 0;
        }
      }
    }
    .mobileIllustration,
    .mobileLogo {
      display: none;
    }
    .mobileIllustration,
    .mobileLogo {
      display: none;
    }
    .desktopMdIllustration {
      display: block;
    }
  }
}

@include media-desktop {
  .wrapper {
    width: calc(100% - #{$sideNav_lg});
    margin-left: $sideNav_lg;
  }
}

@include media-xl {
  .wrapper {
    width: calc(100% - #{$sideNav_xl});
    margin-left: $sideNav_xl;
    > div {
      padding: 0px 124px;
    }
  }
}

@include media-xl {
  .wrapper {
    .upperFooter {
      flex-wrap: nowrap;
      padding-left: 124px;
      padding-right: 124px;
    }
  }
}
