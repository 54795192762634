@import "style_utils";

$contentHeight: calc(100vh - #{$headerHeight_lg});

.contentWrapper {
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    @include transition((background-color, visibility), 0.2s, linear);
    z-index: 40;
  }
  &.fade:after {
    background-color: rgba($color: #000000, $alpha: 0.2);
    visibility: visible;
  }
}
.main {
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: $contentHeight;
  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  nav {
    position: fixed;
    width: $sideNav_lg;
    height: $contentHeight;
    & > ul {
      padding-left: $headerPaddingLeft_lg;
    }

    @include media-xl {
      width: $sideNav_xl;
      & > ul {
        padding-left: $headerPaddingLeft_xl;
      }
    }
  }

  .content {
    background-color: $backgroundPrimary;
    flex: 1;

    &:not(.termsPage) {
      padding-bottom: $headerHeight_lg;
    }

    &.bgWhite {
      background-color: $white;

      section:not(.noBorder):not(:last-child) {
        border-bottom: 0;
      }
    }

    section {
      width: 100%;
      padding: 30px 28px;

      &.accordion {
        padding: 20px 0;
        font-size: 16px;
        @include media-desktop {
          font-size: 18px;
        }
        .smallText p {
          font-size: 14px;
          line-height: 20px;
        }
      }

      &.bgWhite {
        padding: 30px 0;
      }

      &.faqNoHorizontalPadding {
        padding: 30px 0;
      }

      &.noPadding {
        padding: 0;
      }

      &.noBottomPadding {
        padding-bottom: 0;
      }

      &.noTopPadding {
        padding-top: 0;
      }

      &:first-child {
        padding-top: 8px;
        @include media-desktop {
          padding-top: 20px;
        }
      }

      &:last-child {
        padding-bottom: 8px;
        @include media-desktop {
          padding-bottom: 20px;
        }
      }

      &.white {
        background-color: white;
      }

      &:not(.noBorder):not(:last-child) {
        border-bottom: 1px solid $botticelli;
      }

      @include media-tablet {
        padding: 28px 40px 28px 55px;

        &.accordion,
        &.bgWhite {
          padding: 20px 40px 20px 55px;
        }
      }

      @include media-xl {
        padding: 30px 124px;

        &.accordion,
        &.bgWhite {
          padding: 20px 124px;
        }

        &.noPadding {
          padding: 0;
        }

        &.noBottomPadding {
          padding-bottom: 0;
        }

        &.noTopPadding {
          padding-top: 0;
        }

        &:not(.fullWidth) {
          & > * {
            max-width: 750px;
            width: 100%;
          }
        }

        &:first-child {
          padding-top: 8px;
          @include media-desktop {
            padding-top: 20px;
          }
        }

        &:last-child {
          padding-bottom: 8px;
          @include media-desktop {
            padding-bottom: 20px;
          }
        }
      }
    }

    &:not(.termsPage) {
      @include media-desktop {
        margin-left: $sideNav_lg;
      }

      @include media-xl {
        margin-left: $sideNav_xl;
      }
    }
  }
}

.spinner {
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0;
  background: transparent;
  border-top: 2px solid $red;
  border-right: 2px solid transparent;
  border-radius: 50%;
  @include animation(spin 1s linear infinite);

  opacity: 0;
  visibility: hidden;
  @include transition((opacity, visibility), 0.2s, ease-out);

  &.loading {
    opacity: 1;
    visibility: visible;
  }
}

.loadMoreRow {
  cursor: pointer;
  span {
    &:last-child {
      text-align: left !important;
    }
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 14px;
    width: 2.3px;
    right: 10px;
    top: 50%;
    background-color: $red;
    @include transition((opacity), 0.2s, ease-out);
    opacity: 1;
  }
  &:before {
    @include rotate(90deg);
    top: calc(50% - 7px);
  }

  &:after {
    @include transform(translateY(-50%));
  }

  @include media-desktop {
    &:hover {
      color: $red;
    }
  }
}

.bold {
  font-weight: bold;
}

.ellipsis {
  transform: rotate(90deg);
  width: 20px;
  height: 20px;
  background-image: radial-gradient(circle, black 2px, transparent 2.2px);
  background-size: 100% 33.33%;
}

.error,
.warning {
  @extend .flexAlignCenter;
  color: $red;
  margin-top: 8px;

  &.warning {
    color: $black;
    font-weight: bold;

    p {
      margin-bottom: 0;
    }
  }

  img {
    width: 20px;
    margin-right: 8px;
  }

  span {
    font-size: 12px;
  }

  @include media-desktop {
    margin-top: 10px;
  }
}

.flex {
  display: flex;
}

.textEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pdfIcon {
  width: 12px;
  margin-right: 6px;

  @include media-desktop {
    width: 18px;
    margin-left: 11px;
  }
}

.label {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 12px;
  @include media-desktop {
    margin-bottom: 16px;
  }
}
