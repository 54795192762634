@import "style_utils";

.wrapper {
  display: none;
  position: absolute;
  background-color: $white;
  box-shadow: 0px 3px 9px $borderGrey;
  border-radius: 8px;
  z-index: 50;
  &.open {
    display: block;
  }
  width: calc(100% - 32px);

  @include media-desktop {
    width: 360px;
  }

  // Draw pointer
  .pointer {
    position: absolute;
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    &:not(&.flipped) {
      bottom: -32px;
      border-bottom: 16px solid transparent;
      border-top: 16px solid $white;
    }

    &.flipped {
      top: -32px;
      border-bottom: 16px solid $white;
      border-top: 16px solid transparent;
    }
  }

  .closeButton {
    position: absolute;
    top: 19px;
    right: 24px;
    width: 14px;
    height: 14px;
    cursor: pointer;

    img {
      position: absolute;
      top: 0;
    }
  }

  .inner {
    padding: 36px 17px;

    h3 {
      margin-bottom: 0;
    }

    span {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
