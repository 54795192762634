@import "style_utils";

.wrapper {
  & > *:not(:last-child):not(p) {
    padding-bottom: 24px;
  }

  .formActions {
    margin-top: 30px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;

    & > button:first-child {
      margin-top: 10px;
      @include media-desktop {
        margin-top: 0;
        margin-right: 20px;
      }
    }

    @include media-desktop {
      flex-direction: row;
    }
  }
}

.dayPickerWrapper {
  position: relative;
}

.dayPicker {
  z-index: 1;
  position: absolute;
  top: 95px;
  width: 100%;

  opacity: 0;
  visibility: hidden;
  max-height: 0;

  @include transition((opacity, visibility), 0.2s, ease-out);
  &.open {
    opacity: 1;
    visibility: visible;
    max-height: 100%;
    margin-bottom: 24px;
  }
  & > div {
    width: 100%;
  }

  @include media-desktop {
    width: 50%;
  }
}
