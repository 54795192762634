.horizontalGrid {
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;

  @include media-tablet {
    padding-left: 55px;
    padding-right: 40px;
  }

  @include media-xl {
    padding-left: 124px;
    padding-right: 124px;

    & > * {
      max-width: 750px;
      width: 100%;
    }
  }
}

.verticalGrid {
  padding-top: 30px;
  padding-bottom: 30px;
}
