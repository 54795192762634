@import "style_utils";

.wrapper {
  position: relative;
  .imagesWrapper {
    @extend .flexCenter;
    padding-bottom: 16px;
    .image {
      max-width: 100%;
    }
  }
  .textWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
    .titleWrapper {
      @extend .flexCenter;
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      color: $tundora;
    }
    .bodyWrapper {
      font-size: 16px;
      line-height: 153.5%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $boulder;

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
  .returnWrapper {
    @extend .flexCenter;
    padding-bottom: 20px;
    span {
      color: $red;
      text-decoration: underline;
    }
  }
}

@include media-desktop {
  .wrapper {
    .imagesWrapper {
      padding-bottom: 42px;
      padding-top: 42px;
    }
    .textWrapper {
      .titleWrapper {
        font-size: 24px;
        line-height: 35px;
      }
      .bodyWrapper {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}
