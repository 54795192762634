@import "style_utils";

.labelRow {
  display: flex;
  justify-content: space-between;

  .popover {
    align-items: flex-start;
  }
}

.label {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 12px;
  @include media-desktop {
    margin-bottom: 16px;
  }
}

.wrapper {
  @extend .flexColumn;
  gap: 16px;

  @include media-desktop {
    background-color: $catskillWhite;
    border-radius: 8px;
    padding: 16px;
  }
}

.rowWrapper {
  @extend .flexColumn;
  gap: 8px;
  &:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: 1px solid $borderGrey;
  }
  .row {
    @extend .flexBetween;
    gap: 8px;

    .ellipsis {
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: bold;
    }
  }
}
