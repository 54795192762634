@import "style_utils";


.input {
  margin-bottom: 30px;
  &.day {
    display: flex;
    flex-direction: column;
  }
}

.invalidCountries,
.multiSelect {
  max-width: 450px;
}

.dayPicker {
  margin: 20px 0;
  &.disabled {
    background-color: rgba(214, 194, 194, 0.1);
  }
  @include media-tablet {
    width: 350px;
  }
}

.label {
  display: flex;
  justify-content: space-between;
  p {
    font-weight: bold;
  }
}

.buttonContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  .button{
    margin-top: 10px;
    @include media-desktop {
      margin-top: 0;
      margin-right: 20px;
    }
  }

  @include media-desktop {
    flex-direction: row;
  }
}

.success {
  p {
    margin-bottom: 25px;
  }
}
