@import "style_utils";

.wrapper {
  position: sticky;
  top: 0;
  height: $headerHeight_lg;
  background-color: $white;
  width: 100%;
  @extend .flexBetween;
  box-shadow: inset 0px 3px 0px $red, inset 0px -1px 0px $mercury;
  z-index: 2;
  padding: $headerPadding_sm;

  @include media-desktop {
    padding: $headerPadding_lg;
  }

  @include media-xl {
    padding: $headerPadding_xl;
  }
}

.contentWrapper {
  max-width: 1140px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.contentWrapper > a {
  display: flex;
  &:focus-visible {
    outline: 1px solid #4d90fe;
    padding: 0 10px;
    margin: 0 -10px;
  }
}

.userIcons {
  @extend .flexRow;
  .userIcon {
    position: relative;
    animation: padding 200ms ease;
    @extend .flexCenter;
    &:not(:first-child) {
      margin-left: 20px;
    }

    a,
    button {
      @extend .flexCenter;
      text-decoration: none;

      > div {
        @extend .flexCenter;
      }

      &:hover {
        text-decoration: none;
        :not(.badge):before {
          font-weight: 600;
          color: $red;
        }
      }
    }
    a:focus-visible {
      outline: 1px solid #4d90fe;
      padding: 5px 10px;
      margin: -5px -10px;
    }
    button:focus-visible {
      outline: none;
      div:nth-child(2) {
        outline: 1px solid #4d90fe;
        padding: 5px 10px;
        margin: -5px -10px;
        border-radius: 5px;
      }
    }

    img {
      width: 20px;
      height: 20px;
    }

    span:not(.badge) {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      padding-left: 8px;
      transition: all;
      position: relative;
      color: $black;
      visibility: hidden;
      &::before {
        visibility: visible;
        font-weight: 400;
        content: attr(data-text);
        position: absolute;
        text-align: center;
        top: 0;
        left: 8px;
        right: 0;
      }
    }

    .badge {
      font-size: 12px;
      line-height: 12px;
      color: $white;
      position: relative;
      padding: calc(2% + 3px);
      transform: translateY(-23%);
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        --badgeSize: calc(100% + 0px);
        padding-bottom: var(--badgeSize);
        width: var(--badgeSize);
        height: 0;
        background-color: $red;
        border-radius: 50%;
        z-index: -1;
      }
    }
  }
}

.buttonGroup {
  @extend .flexBetween;

  .mailWithBadge {
    @extend .flexAlignCenter;
    position: relative;
    margin-right: 75px;
    .mailIcon {
      height: calc(1.5px + 1.5px + 1.5px + 8px + 8px);
    }
    .badge {
      font-size: 12px;
      line-height: 1;
      position: absolute;
      top: -10px;
      right: -10px;
      padding: 5px 8px;
      border-radius: 50%;
      background-color: red;
      color: white;
    }
  }
}

.logotype {
  width: 144px;
  height: 44px;
}
