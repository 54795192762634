@import "style_utils";

$rowHeight: 80px;
$rowHeightMobile: 66px;

.wrapper {
  margin-bottom: 20px;
}

.emptyStateText {
  padding: 32px 32px 0 32px;

  @include media-desktop {
    padding: 36px 36px 0 36px;
  }
}

.addAccount {
  @extend .flexAlignCenter;
  cursor: pointer;
  margin: 0 32px 20px 32px;
  padding-bottom: 41px;
  padding-left: 0;

  @include removeNativeButtonStyling;

  .textWrapper {
    border-radius: 10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
    padding: 10px;
  }

  &:focus-visible .textWrapper {
    outline: 1px dashed $red;
  }

  @include media-desktop {
    margin: 0 36px 20px 36px;
    padding-bottom: 53px;
  }
  padding-top: 32px;
  .icon {
    width: 42px;
    height: 42px;
    margin-right: 8px;
    border-radius: 50px;
    background-color: $darkSky;
    @extend .flexCenter;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 17px;
      height: 2px;
      background-color: $white;
      opacity: 1;
      @include transition(opacity, 0.2s, ease-in);
    }

    span {
      font-size: 16px;
      line-height: 24px;
    }

    &:after {
      @include transform(rotate(90deg));
    }
  }

  &.open {
    padding-bottom: 0;
    .icon {
      &:after {
        opacity: 0;
      }
    }
  }

  @include media-desktop {
    span {
      font-size: 18px;
      line-height: 27px;
    }
  }
}

.label {
  display: block;
  font-weight: bold;
}

.flexContainer {
  display: block;

  @include media-desktop {
    display: flex;

    & > div {
      flex: 1;
    }

    &:not(.noMargin) {
      & > div {
        &:first-child {
          margin-right: 8px;
        }
        &:last-child {
          margin-left: 8px;
        }
      }
    }
  }
}

.tableWrapper {
  margin-bottom: 44px;
}

.error {
  margin-bottom: 10px;
}

.buttonWrapper {
  margin-top: 20px;
  display: flex;
  > div {
    flex: 1;
  }
}

.bottomPadding {
  padding-bottom: 32px;
}

.input {
  margin-bottom: 20px;
}

.labelWrapper {
  display: flex;

  label {
    flex: 1;
    font-weight: bold;
    margin-bottom: 16px;
  }

  div {
    flex: 1;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.newAccount {
  background-color: $blackSqueeze;
  border-radius: 8px;
  padding: 16px;
  margin: 20px 0;

  &.paddedRight {
    width: calc(100% - 32px);
  }

  @include media-desktop {
    margin: 29px 0px;
    padding: 16px 18px;

    &.paddedRight {
      width: calc(100% - 36px);
    }
  }

  &.noMarginLeft {
    margin-left: 0;
  }
}

.header {
  @extend .flexRow;
  margin-left: 32px;
  min-height: $rowHeightMobile;
  align-items: center;
  border-bottom: 1px solid $borderGrey;

  .offset {
    width: 72px;
  }

  @include media-desktop {
    margin-left: 36px;
    min-height: $rowHeight;

    .offset {
      width: 0;
    }
  }
}

.nameTitle,
.accountTitle {
  font-size: 16px;
  font-weight: bold;

  @include media-desktop {
    font-size: 18px;
  }
}

.nameTitle {
  flex: 1;
  min-width: 100px;
  padding-right: 36px;

  @include media-desktop {
    padding-right: 0;
    min-width: 190px;
  }
}

.accountTitle {
  flex: 3;
}

.savedAccounts {
  margin: 0 32px;
  .accountRowWrapper {
    position: relative;
    min-height: $rowHeightMobile;
    left: 0;
    @include transition((left), 0.3s, ease-out);
    overflow: hidden;

    & > .accountRowContent {
      min-width: 0;
      display: flex;
      align-items: center;
      min-height: $rowHeightMobile - 1;
    }

    @include media-desktop {
      min-height: $rowHeight;

      & > .accountRowContent {
        min-height: $rowHeight - 1;
      }
    }

    .accountRowOptions {
      position: relative;
      @extend .flexAlignCenter;
    }

    .accountActions {
      position: absolute;
      height: $rowHeightMobile;
      right: 61px;
      @extend .flexAlignCenter;
      background-color: transparent;
      @include transition(background-color, 0s, linear);
      @include transition_delay(0.3s);

      @include media-desktop {
        height: $rowHeight;
      }

      .boxWrapper {
        @extend .flexCenter;
        padding: 0;
        width: 77px;
        border-left: 1px solid $borderGrey;
        border-radius: 0;
        background: $white;

        opacity: 0;
        visibility: hidden;
        @include transform(translate3d(77px, 0, 0));
        @include transition(
          (transform, opacity, visibility),
          0.3s,
          $easeInBack
        );
        @include transition_delay(0s);

        &:last-child {
          border-right: 1px solid $borderGrey;
          @include transition_delay(0.15s);
        }

        @include media-desktop {
          width: 90px;
          min-height: $rowHeight;
        }
      }

      &.open {
        background-color: $white;

        .boxWrapper {
          @include transform(translate3d(0, 0, 0));
          opacity: 1;
          visibility: visible;
          @include transition(
            (transform, opacity, visibility),
            0.3s,
            $easeOutBack
          );
          @include transition_delay(0.15s);

          &:last-child {
            @include transition_delay(0s);
          }
        }
      }
    }

    .box {
      @extend .flexFixCenterCol;

      img {
        width: 16px;
        margin-bottom: 7px;
      }

      span {
        font-size: 14px;
        line-height: 20px;
        color: $tundora;

        @include media-desktop {
          font-size: 12px;
          line-height: 17px;
        }
      }

      &:last-child {
        border-right: 0;
      }
    }

    &:not(.last-child) {
      border-bottom: 1px solid $borderGrey;
    }

    &:last-child {
      border-bottom-color: $mediumSky;
    }
  }
  .accountRow {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    .accountName {
      flex: 1;
      min-width: 100px;
      padding-right: 36px;

      font-size: 16px;
      font-weight: bold;

      &.hidden {
        display: none;
      }

      @include media-desktop {
        padding-right: 36px;

        font-size: 18px;
        min-width: 190px;

        &.hidden {
          display: flex;
        }
      }
    }
    .accountNumber {
      min-width: 0;
      flex: 3;
    }
  }
}

.savedAccountFormWrapper {
  padding: 0 38px;
  padding-bottom: 41px;

  @include media-desktop {
    padding-bottom: 53px;
  }
}
