@import "style_utils";

.wrapper {
  .button {
    position: relative;
    height: $buttonHeight_lg;
    border: 2px solid $mercury;
    border-radius: $borderRadius;
    padding: 6px 12px;
    cursor: pointer;
  }

  .dateRange {
    display: none;
    position: absolute;

    &.open {
      display: block;
    }
  }
}

.inputs {
  padding: 24px;
  margin-top: 24px;

  & > div {
    &:not(.datePresets) {
      width: 50%;
      display: inline-block;

      &:first-child {
        padding-right: 9px;
      }

      &:last-child {
        padding-left: 9px;
      }
    }

    span {
      display: block;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      text-align: left;
    }
  }
}

.dayPicker {
  opacity: 0;
  max-height: 0;
  padding: 0 24px;
  @include transition((opacity, visibility), 0.2s, ease-out);
  &.open {
    opacity: 1;
    max-height: 100%;
    margin-bottom: 24px;
  }
  & > div {
    width: 100%;
  }
}

.datePresets {
  padding: 0 24px;
  span {
    display: block;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
  }
}

.actions {
  padding: 24px;
  margin-top: 24px;
  border-top: 1px solid $borderGrey;

  & > button:not(:last-child) {
    margin-right: 12px;

    @include media-desktop {
      margin-right: 18px;
    }
  }
}

.dateWrapper,
.datePresets {
  & > div {
    width: 100%;
  }
  & > span {
    margin-bottom: 10px;
  }
}
