@import "style_utils";

.labelRow {
  display: flex;
  justify-content: space-between;

  .popover {
    align-items: flex-start;
  }
}

.label {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 16px;
}

.faded {
  opacity: 0.5;
}

.wrapper {
  position: relative;
  width: 100%;
  height: $buttonHeight_lg;
  border: 2px solid $mercury;
  border-radius: $borderRadius;
  padding: 6px 12px;
  background-color: $white;
  @extend .inlineFlex;
  @extend .alignCenter;
  @include transition((border-color), 0.2s, ease-out);

  &.small {
    height: 36px;
  }

  &.large {
    width: 180px;

    @include media-desktop {
      width: 240px;
    }
  }

  &.focus,
  &.borderBlue {
    border-color: $sinbad;
  }

  &.borderBoulder {
    border-width: 1px;
    border-color: $boulder;
  }

  &.borderBlack {
    border-width: 1px;
    border-color: $black;
  }

  &.disabled {
    border-width: 1px;
    border-color: $black;
  }

  &.centered {
    input {
      text-align: center;
    }
  }

  input {
    width: 100%;
    font-size: 16px;
    line-height: 23px;
    color: $black;
  }

  &.hasPlaceholder {
    input {
      padding-top: 13px;
    }
  }

  .placeholder {
    position: absolute;
    pointer-events: none;
    @include transition(transform, 0.2s, ease);
    font-size: 16px;
    line-height: 23px;
    color: $boulder;

    @include media-desktop {
      font-size: 18px;
    }

    &.float {
      @include transform(translate3d(0, -11px, 0) scale(0.75));
    }
  }

  &.loginInput {
    .float {
      // TODO: Investigate why login input floating placeholder is offseted to the right
      @include transform(translate3d(-27px, -11px, 0) scale(0.75));
    }
  }

  &.disabled {
    input {
      background: none;
      color: $boulder;
      -webkit-text-fill-color: $boulder;
      opacity: 1;
    }
  }

  input,
  input:focus {
    border: 0;
    outline: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .icon,
  .suffix {
    position: absolute;
    right: 10px;
  }

  .icon {
    width: 23px;
    top: calc(50% - 11.5px);
  }

  .suffix {
    font-size: 16px;
    line-height: 23px;
    color: $boulder;
    font-weight: 400;
  }
}
