@import "style_utils";

.nav {
  display: none;
  padding-top: 74px;

  &.bgWhite {
    border-right: 1px solid $alto;
  }

  a {
    color: $black;
    text-decoration: none;
    font-size: 18px;
    line-height: 64px;
    &:hover {
      color: $red;
      font-weight: bold;
      text-decoration: none;
    }
  }

  @include media-desktop {
    display: flex;
    flex-direction: column;
  }
}
