@import "style_utils";

$mobilePadding: 27px;
$desktopPadding: 27px 36px;

.messageWrapper {
  background-color: $white;

  .messageTitle {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid $borderGrey;
    padding: $mobilePadding;
  }

  .message {
    padding: $mobilePadding;
    border-bottom: 1px solid $borderGrey;
  }

  .replyFormWrapper {
    padding: $mobilePadding;
  }
  .prevMessageWrapper {
    padding: $mobilePadding;
    background-color: $blackSqueeze;
    &.dark {
      background-color: #cadde3;
    }
  }

  @include media-desktop {
    border-radius: 8px;
    box-shadow: 0px 6px 9px rgba(153, 196, 212, 0.5);

    .messageTitle,
    .message,
    .replyFormWrapper,
    .prevMessageWrapper {
      padding: $desktopPadding;
    }
  }
}

.messageContent {
  padding-top: 27px;
  font-size: 16px;
  word-wrap: break-word;

  pre {
    white-space: pre-wrap;
  }

  table {
    width: 100%;

    tr {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    td {
      padding-left: unset !important;
    }
  }

  @include media-desktop {
    font-size: 18px;
  }
}

.attachments {
  @extend .flexCol;
}

.messageHeader {
  @extend .flexRow;
  justify-content: space-between;
  align-items: center;
  .from,
  .date {
    font-size: 18px;
  }
  .from {
    @extend .flexRow;
    align-items: center;
    font-weight: bold;
    > img,
    > div {
      margin-right: 20px;
    }
    > img {
      height: 40px;
      width: 40px;
    }
  }
}

.label {
  font-weight: bold;
  padding-bottom: 12px;
}

.replyLabel {
  font-weight: bold;
  padding: 0 36px 16px 36px;
}

.buttonWrapper {
  margin-top: 30px;
  @extend .flexCol;
  justify-content: space-between;
  .fileAttachmentWrapper {
    width: 100%;
    margin-bottom: 18px;
  }
  .buttonActionsWrapper {
    @extend .flexCol;
    justify-content: flex-end;
    width: 100%;
    & > * {
      width: 100%;
      height: 44px;
      margin-bottom: 18px;
    }
  }
  @include media-desktop {
    flex-direction: row;
    .fileAttachmentWrapper {
      width: 50%;
    }
    .buttonActionsWrapper {
      flex-direction: row;
      width: 100%;
      & > * {
        width: 160px;
        height: 48px;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
}

.backButtonWrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 29px;

  @include media-desktop {
    padding-left: 0;
  }
}

.backButtonContent {
  @extend .flexAlignCenter;
  .backIcon {
    width: 17px;
  }
  > span {
    padding-left: 19px;
  }
}

.initialsWrapper {
  background: $white;
  border-radius: 50%;
  width: 39px;
  min-width: 39px;
  height: 39px;
  line-height: 39px;
  text-align: center;
  box-shadow: 0 0 0 1px $black;
}

.backButton {
  @include media-desktop {
    padding: 0;
  }
}
