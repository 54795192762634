@import "style_utils";

.wrapper {
  display: none;
  position: absolute;
  background-color: $white;
  box-shadow: 0px 3px 9px $borderGrey;
  border-radius: $borderRadius;
  z-index: 50;
  &.open {
    display: block;
  }
  width: calc(100% - 32px);

  @include media-desktop {
    width: 360px;
  }
}
