@import "style_utils";

$conversationOptionsWidth: 82px;
$paddedConversationOptionsWidth: 86px;

.row {
  @extend .flexRow;
}

.inboxWrapper {
  background-color: $white;
  @include media-desktop {
    border-radius: 8px;
    box-shadow: 0px 6px 9px rgba(153, 196, 212, 0.5);
  }
  .inboxHeader {
    padding: 18px 36px;

    @include media-sm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $borderGrey;
    }

    .title {
      font-size: 16px;
      @include linkHoverEffect;
    }
  }

  .contentHeader {
    display: flex;
    padding: 18px 36px;
    border-bottom: 1px solid $borderGrey;

    .title {
      font-size: 16px;
      font-weight: bold;

      @include media-desktop {
        font-size: 18px;
      }
    }

    > span:first-child {
      flex: 1;
    }
    > span:nth-child(2) {
      flex: 3;
    }
    .offset {
      width: $conversationOptionsWidth;
    }
  }
}

.dateTitle,
.conversationTitle {
  font-size: 16px;
  font-weight: bold;

  @include media-desktop {
    font-size: 18px;
  }
}

.dateTitle {
  flex: 1;
}

.conversationTitle {
  flex: 3;
}

.offset {
  width: $conversationOptionsWidth;
}

.conversationHeader {
  @extend .flexRow;
  padding-left: 36px;
  min-height: 80px;
  align-items: center;
  border-bottom: 1px solid $borderGrey;
}

.conversations {
  margin: 0 36px;
  > div:not(:last-child) {
    border-bottom: 1px solid $borderGrey;
  }
}

.conversationWrapper {
  position: relative;
  left: 0;
  @include transition((left), 0.3s, ease-out);

  &.open {
    margin: 0;
    border-top: 1px solid #cccccc;
    margin-top: -1px;

    .conversationRow .conversationLink .conversationContent {
      padding-right: $paddedConversationOptionsWidth * 2;
    }
  }

  .conversationRow {
    min-width: 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    overflow: hidden;

    .conversationLink {
      display: flex;
      align-items: center;
      min-width: 0;
      width: 100%;
      text-decoration: none;
      color: $black;

      @include outlineHoverVisible;

      .date {
        flex: 1;
        min-width: 119px;
        white-space: nowrap;
        font-size: 16px;

        @include media-desktop {
          padding-right: 0;
          font-size: 18px;
        }
      }
      .conversationContent {
        padding: 16px 0;
        min-width: 0;
        flex: 3;
        padding-right: $paddedConversationOptionsWidth;

        .tag {
          font-size: 12px;
          color: $accesibleSky;
        }
        .title {
          font-size: 16px;

          //truncate
          @include line_clamp(2);

          @include media-desktop {
            font-size: 18px;
          }
        }
        .messageBodyPreview {
          font-size: 14px;
          color: $boulder;

          @include media-desktop {
            font-size: 16px;
          }

          //truncate
          @include line_clamp(1);
        }
      }
    }
    .conversationOptions {
      @extend .flexRow;
      flex-direction: row-reverse;
      position: absolute;
      height: 100%;
      right: 0;

      .outsideOptions {
        display: flex;
        justify-content: center;
        min-width: $conversationOptionsWidth;
        .expandButton {
          position: absolute;
          top: 0;
        }
      }
    }

    .conversationActions {
      @extend .flexRow;

      .archive {
        background: $white;
        z-index: 1;
        width: $conversationOptionsWidth;
        @extend .flexCol;
        align-items: center;
        justify-content: center;
        border-left: 1px solid $borderGrey;
        border-right: 1px solid $borderGrey;
        font-size: 14px;

        opacity: 0;
        visibility: hidden;
        @include transform(translate3d($conversationOptionsWidth, 0, 0));
        @include transition(
          (transform, opacity, visibility),
          0.3s,
          $easeInBack
        );

        .archiveButton {
          height: 100%;
          padding: 4px 24px;
        }

        .iconLabelWrapper {
          @extend .flexCol;
          align-items: center;

          > span {
            position: absolute;
            bottom: 15px;
            font-size: 12px;
          }

          .archiveIcon {
            width: 20px;
          }
        }
      }

      &.open {
        .archive {
          @include transform(translate3d(0, 0, 0));
          opacity: 1;
          visibility: visible;
          @include transition(
            (transform, opacity, visibility),
            0.3s,
            $easeOutBack
          );
        }
      }
    }
  }
}

.mailIcon {
  width: 20px;
}

.dateTitle {
  min-width: 119px;
  padding-right: 36px;

  @include media-desktop {
    padding-right: 0;
  }
}

.outsideTabsContainer {
  background-color: white;
  margin: 16px 32px;
  padding: 2px;
  border-radius: 8px;
}

.tabs {
  display: flex;
  justify-content: space-between;

  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $boulder;

    &.active {
      color: $black;
      background-color: $blackSqueeze;
    }
  }

  @include media-sm {
    justify-content: flex-start;
    padding-bottom: 0;

    .tab {
      width: 108px;
    }
  }
}

.loadMoreWrapper {
  display: flex;
  position: relative;
  min-height: 80px;
  margin: 0 36px;
  align-items: center;
  font-size: 16px;
  cursor: pointer;

  @include media-desktop {
    font-size: 18px;
  }
}

.unread {
  font-weight: bold;
}

.loadingRow {
  @extend .flexCenter;
  min-height: 80px;
}

.emptyInboxWrapper {
  @extend .flexFixCenterCol;
  min-height: 222px;

  .emptyInboxText > p {
    color: $boulder;
    text-align: center;
  }

  .emptyInboxHeader {
    font-size: 24px;
    color: $black;
  }
}

.spinnerWrapper {
  @extend .flexCenter;
}

.spinner {
  z-index: 10;
  background-color: $white;
  right: 0;
}

.pageDescription {
  p {
    font-size: 18px;
  }
}
