@import "style_utils";

.failureContainer {
  background-color: $white;
  border-radius: 8px;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-desktop {
    padding: 30px 50px;
    border-radius: 8px;
    box-shadow: 0px 6px 9px rgba(153, 196, 212, 0.5);
  }

  .headerContainer {
    margin-bottom: 30px;
  }

  .contentWrapper {
    margin-bottom: 20px;
  }

  .accountInfoContainer {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $mediumSky;
  }

  .accountInfoRow {
    font-size: 16px;
  }
}

.termsWrapper {
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    padding-bottom: 16px !important;
    margin-bottom: 24px;
    background-color: $catskillWhite;

    & > *:last-child {
        margin-bottom: 0;
    }
}