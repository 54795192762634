@import "style_utils";

.dateNavbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 12px 0 12px 0;
  padding-top: 12px;

  .prev,
  .next {
    cursor: pointer;
    display: flex;
    img {
      width: 9px;
    }

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      color: $borderGrey;
      text-transform: uppercase;

      @include media-desktop {
        font-size: 12px;
        line-height: 17px;
      }
    }
  }

  .prev {
    img {
      @include rotate(180deg);
    }
    span {
      padding-left: 15px;
    }
  }

  .next {
    span {
      padding-right: 15px;
    }
  }

  .current {
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
    text-transform: uppercase;
  }
}
