@import "style_utils";

.labelRow {
  display: flex;
  justify-content: space-between;

  .popover {
    align-items: flex-start;
  }
}

.label {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 16px;
}

.container {
  display: flex;
  justify-content: space-between;
}

.wrapper {
  .radioButtonsList {
    @extend .flexColumn;

    .inputLabel {
      display: block;
      position: relative;
      cursor: pointer;
      padding-left: 45px;
      margin-bottom: 16px;
      font-size: 16px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.disabled {
        color: $boulder;
        border-color: $boulder;
        cursor: default;
        input {
          cursor: default;
        }
        &:hover input ~ .inputItem {
          background-color: transparent;
        }
        & input:checked ~ .inputItem {
          border-color: $boulder; // border
        }
        & .inputItem:after {
          background: $boulder; // center
        }
      }
    }

    .inputLabel input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:focus-visible + .inputItem {
        outline: 2px dashed $red;
      }
    }

    .inputItem {
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
      background-color: $white;
      border: 2px solid $boulder;
      border-radius: 50%;
      @include transition(all, 0.2s, ease);
    }

    .inputLabel:hover input ~ .inputItem {
      background-color: $backgroundPrimary;
    }

    .inputLabel input:checked ~ .inputItem {
      border-color: $red;
    }

    .inputItem:after {
      content: "";
      position: absolute;
      display: block;
      opacity: 0;
      @include transition(all, 0.2s, ease);
    }

    .inputLabel input:checked ~ .inputItem:after {
      opacity: 1;
    }

    .inputLabel .inputItem:after {
      top: 6px;
      left: 6px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: $red;
    }
  }
}
