@import "style_utils";

.wrapper {
  width: 100%;
}

@include media-xl {
  .wrapper {
    margin: 27px 0px;
  }
}

.noMargin {
  .wrapper {
    margin: 0;
  }
}

.notification {
  @include transition((opacity, visibility, max-height), 0.2s, ease-out);
  opacity: 1;
  visibility: visible;
  max-height: 100%;
  position: relative;
  z-index: 10;
  padding-left: 0px;
  padding-right: 0px;
  @extend .horizontalGrid;
  .wrapper {
    position: relative;
    width: 100%;
    padding: 20px;
    margin-bottom: 3px;

    @extend .flexCol;
    @extend .flexStart;
  }

  @include media-xl {
    .wrapper {
      flex-direction: row;
      box-shadow: 0px 6px 9px rgba(153, 196, 212, 0.25);
    }
  }

  &.viewed {
    opacity: 0;
    max-height: 0;
    display: none;
  }

  @include media-xl {
    .test {
      flex-direction: row;
    }
  }

  @include media-xl {
    .test {
      padding: 30px 124px;
    }
  }

  &.information {
    .wrapper {
      background-color: $blackSqueeze;
    }
  }

  &.important {
    .wrapper {
      background-color: $milkPunch;
    }
  }

  &.error {
    .wrapper {
      background-color: $lightError;
    }
  }

  .icon {
    padding-right: 16px;
    position: relative;
  }

  .innerWrapper {
    position: relative;
    width: 100%;
    max-width: 750px;
  }

  .contentWrapper {
    position: relative;
    max-width: 90%;
    padding-top: 12px;
    .header {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
    }

    .link {
      display: block;
      margin-top: 16px;
    }

    @include media-xl {
      padding-top: 0;
      max-width: 525px;
    }
  }

  .close {
    width: 12px;
    height: 12px;
    position: absolute;
    top: -12px;
    right: 0;
    cursor: pointer;

    &:before,
    &:after {
      content: "";
      height: 12px;
      border-left: 2px solid rgba($color: #000000, $alpha: 0.7);
      position: absolute;
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }

    @include media-xl {
      top: 8px;
    }
  }

  @include media-xl {
    padding-left: 124px;
    padding-right: 124px;
  }
}
