@import "style_utils";

.wrapper {
  background: white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding-top: 32px;
  position: relative;
  width: 100%;
  .buttonsWrapper {
    display: flex;

    > :last-child {
      margin-bottom: 16px;
    }
    
    flex-direction: column-reverse;
    @include media-desktop {
      flex-direction: row;
      margin-top: 15px;
      justify-content: flex-end;
      > :last-child {
        margin-bottom: 0;
        margin-left: 30px;
      }
    }
  }
}
