@import "style_utils";

.labelRow {
  display: flex;
  justify-content: space-between;

  .popover {
    align-items: flex-start;
  }
}

.label {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 12px;
  @include media-desktop {
    margin-bottom: 16px;
  }
}

.searchIcon {
  position: relative;
  width: 19px;
  height: 19px;
  right: 10px;

  @include media-desktop {
    width: 26px;
    height: 26px;
    right: 13px;
  }
}

.valueRemove {
  border-radius: 50%;
  position: relative;
  @extend .flexCenter;
  width: 30px;
  height: 30px;
  background: $mediumSky;

  .icon {
    width: 12px;
    height: 12px;
  }
}
