@import "style_utils";

.labelRow {
  display: flex;
  justify-content: space-between;

  .popover {
    align-items: flex-start;
  }
}

.label {
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 16px;
}

.wrapper {
  position: relative;
  width: 100%;
  height: auto;
  border: 2px solid $mediumSky;
  border-radius: $borderRadius;
  @extend .inlineFlex;
  @extend .alignCenter;
  @include transition((border-color), 0.2s, ease-out);

  &.focus,
  &.borderBlue {
    border-color: $sinbad;
  }

  &.borderBoulder {
    border-width: 1px;
    border-color: $boulder;
  }

  &.borderBlack {
    border-width: 1px;
    border-color: $black;
  }

  textarea {
    width: 100%;
    height: 112px;
    resize: none;
    padding: 16px;
    border-radius: $borderRadius;
    font-size: 16px;
    @include media-desktop {
      height: 90px;
      resize: none;
      padding: 12px 16px;
    }
  }

  textarea,
  textarea:focus {
    border: 0;
    outline: none;
  }

  textarea::-webkit-outer-spin-button,
  textarea::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  textarea[type="number"] {
    -moz-appearance: textfield;
  }

  .icon,
  .suffix {
    position: absolute;
    right: 10px;
  }

  .icon {
    width: 23px;
    top: calc(50% - 11.5px);
  }

  .suffix {
    font-size: 16px;
    line-height: 23px;
    color: $boulder;
    font-weight: 400;
  }
}

textarea[rows] {
  height: initial;
}
