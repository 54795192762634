@import "style_utils";

.newMessageWrapper {
  background-color: $white;
  @include media-desktop {
    padding: 38px 36px;
    border-radius: 8px;
    box-shadow: 0px 6px 9px rgba(153, 196, 212, 0.5);
  }
}

.dropdownGrid {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr;
}

.faqWrapper {
  padding-bottom: 38px;
}

.pageDescription {
  p {
    font-size: 18px;
  }
}
