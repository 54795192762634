@import "style_utils";

.wrapper {
  position: relative;
  padding-top: 46px !important;

  .imagesWrapper {
    @extend .flexCenter;
    padding-bottom: 16px;
    .image {
      max-width: 100%;
    }
  }
  .textWrapper {
    .titleWrapper {
      @extend .flexCenter;
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      color: $tundora;
    }
    .bodyWrapper {
      @extend .flexCenterCol;
      font-size: 16px;
      line-height: 153.5%;
      text-align: center;
      color: $boulder;
    }
  }
}

.mobileWrapper {
  margin-top: 46px;
}

.emptyState {
  img {
    max-width: 225px !important;
  }
}

@include media-desktop {
  .wrapper {
    padding-top: 27px !important;

    .imagesWrapper {
      padding-bottom: 32px;
    }
    .textWrapper {
      .titleWrapper {
        font-size: 24px;
        line-height: 35px;
      }
      .bodyWrapper {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}
