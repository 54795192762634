@import "style_utils";

.loginHeader {
  height: 10vh;
  border-top: $red solid 2px;
  border-bottom: $alto solid 0.5px;
  padding: 20px 25px;

  @include media-desktop {
    padding: 20px 100px;

    .loginLogotype {
      width: 314px;
      height: 25px;
    }
  }
}

.wrapper {
  @extend .flexCol;
  height: 90vh;

  .image {
    height: 0;
    padding-bottom: 61.33%;
    overflow: hidden;
    background-size: cover;
    width: 100%;

    @include media-desktop {
      &::after {
        content: "";
        background-color: $white;
        position: absolute;
        right: 0;
        width: 80px;
        height: 85%;
      }
    }
  }

  .contentWrapper {
    width: 100%;
    text-align: center;
    padding: 27px 32px;
    justify-content: space-around;

    hr {
      border: none;
      height: 1px;
      color: $alto; // IE
      background-color: $alto;
      margin: 0;
    }

    h1 {
      font-size: 28px;
      line-height: 27px;
      padding-top: 40px;
      margin-bottom: 24px;
    }

    p.large {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    button {
      margin-bottom: 20px;
    }

    .rememerMe,
    .mockToggle {
      @extend .flexBetween;
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .mockToggle {
      margin: 30px 0;
    }

    .qrContainer {
      @include media-desktop {
        padding: 50px 0;
      }
    }

    .qrTitle {
      margin-bottom: 0;
      @include media-desktop {
        margin-bottom: 12px;
      }
    }
  }

  .qrcode {
    position: relative;
    top: 0;
    left: 0;
    background-image: url(../../../../static/assets/qr-border.svg);
    background-position: center;
    background-size: cover;
  }

  .qr {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    display: block;
    margin: auto;
  }

  .qrViewFaq {
    padding-top: 100px;
    padding-bottom: 30px;
  }

  .stepGuide {
    ol {
      position: relative;
      right: 20px;
      @include media-ipad-pro {
        right: 10px;
      }
    }

    li {
      text-align: left;
      font-size: 18px;
      line-height: 27px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    @include media-desktop {
      font-size: 21px;
      line-height: 31.5px;
    }
  }

  .verifyingText {
    a {
      cursor: pointer;
    }
    @include media-desktop {
      font-size: 28px;
      line-height: 34px;
    }
  }

  @include media-desktop {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .imageWrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .image {
      position: relative;
      padding-bottom: 0;
      overflow: hidden;
      background-size: cover;
      background-position: left top;
      width: 60vw;
      height: 100%;
    }

    .contentWrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 40vw;
      min-width: 380px;
      max-height: 90vh;
      text-align: left;
      padding: 0;
      margin-left: 10vw;
      padding-right: 4vw;
      overflow: auto;
      padding-bottom: 50px;

      & > * {
        width: 310px;
      }

      h1 {
        font-size: 48px;
        line-height: 72px;
        margin-bottom: 12px;
        padding-top: 0;
      }

      p.large {
        font-size: 28px;
        line-height: 34px;
        width: 285px;
        margin-bottom: 48px;
      }

      &.pendingWrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        & > * {
          max-width: 384px;
          width: 100%;
        }

        p.large {
          width: 285px;
        }
      }

      .rememerMe,
      .mockToggle {
        span {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .mockToggle {
        margin-bottom: 0;
      }
    }
  }
}


.overlay {
  background-color: #4f4f4fb2;
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  border-radius: none;
  display: flex;
  justify-content: center;
  align-items: center;
  .profilePickerWrapper {
    border-radius: 8px;
    background-color: $white;
    padding: 40px 10px 40px 40px;
    width: 100%;
    margin: 0 15px;
    @include media-desktop {
      width: 450px;
      margin: 0;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      margin-right: 40px;
      h2 {
        margin-bottom: 0;
      }
    }
    .subHeader {
      margin-bottom: 24px;
      margin-right: 30px;
    }
    .profiles {
      display: flex;
      flex-direction: column;
      max-height: 350px;
      overflow-y: auto;
      padding-right: 30px;
      button {
        @include removeNativeButtonStyling;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        border-bottom: 1px solid $borderGrey;
        padding: 16px 0;
        &:hover {
          cursor: pointer;
          p,
          h3 {
            color: $surfieGreen;
          }
          .chevron {
            display: none;
            &.blue {
              display: block;
            }
          }
        }
        &:last-of-type {
          border-bottom: none;
        }
        p,
        h3 {
          @include transition(color, 0.2s, ease-out);
          margin-bottom: 0;
        }
        * {
          text-align: left;
        }
        .chevron {
          height: 13px;
          &.blue {
            display: none;
          }
        }
      }
    }
  }
}

.qaWrapper {
  text-align: left;
  &:not(:last-child) {
    border-bottom: 1px solid $borderGrey;
    padding-bottom: 20px;

    @include media-desktop {
      padding-bottom: 40px;
    }
  }
}

.question {
  display: flex;
  padding-bottom: 12px;
  @include transition((color, padding-bottom), 0.2s, ease-out);
  position: relative;
  cursor: pointer;

  span {
    padding-top: 20px;
    font-size: 14px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 14px;
    width: 2.3px;
    right: 10px;
    top: calc(50% + 6px);
    background-color: $red;
    @include transition((opacity), 0.2s, ease-out);
    opacity: 1;
  }
  &:before {
    @include rotate(90deg);
    top: calc(50% - 1px);
  }

  &:after {
    @include transform(translateY(-50%));
  }

  &.open {
    span {
      font-weight: bold;
    }
    &:after {
      opacity: 0;
    }
  }

  @include media-desktop {
    padding-bottom: 16px;
    span {
      padding-top: 40px;
      font-size: 18px;
    }

    &:before,
    &:after {
      top: calc(50% + 14px);
    }

    &:before {
      top: calc(50% + 7px);
    }

    &:hover {
      color: $red;
    }
  }
}

.answer {
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  @include transition((max-height, visibility, opacity), 0.2s, ease-out);
  padding-left: 20px;
  border-left: 2.4px solid $red;

  p {
    font-size: 14px;
  }

  &.open {
    visibility: visible;
    opacity: 1;
    max-height: 100vh;
  }

  @include media-desktop {
    p {
      font-size: 18px;
    }
  }
}

.input {
  border: 2px solid $darkSky;
  border-radius: $borderRadius;
  input,
  input::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $black;
  }

  &.masked {
    border: 1px solid $black;
    input::placeholder {
      @include transform(scale(0.8));
      color: $boulder;
    }
  }
}

.loginButton {
  font-size: 16px;
  padding: 10px 15px 10px 30px;
  height: $buttonHeight_xl_mobile;
  @include media-desktop {
    height: $buttonHeight_xl;
    min-height: $buttonHeight_xl;
    font-size: 18px;
  }
  img {
    position: absolute;
    left: -18px;
    top: 50%;
    transform: translate(0, -50%);

    &:last-child {
      top: 50%;
      left: 95%;
    }
  }
}

.logoWrapper {
  margin-bottom: 48px;
}

.otherDeviceButton {
  color: $red;
  font-size: 16px;
}

.cancel {
  width: 100%;
  cursor: pointer;
  font-size: 18px;
  line-height: 27px;
  color: $red;
  text-align: center;

  @include media-desktop {
    font-size: 24px;
    line-height: 34px;
    text-align: left;
  }
}

.bankIdButton {
  font-size: 18px;
}
