@import "style_utils";

.navItem {
  @extend .flexAlignCenter;
  margin-bottom: 20px;

  & > div:first-child {
    margin-right: 10px;
  }

  &:last-child {
    margin-top: 30px;
  }
}
