@import "style_utils";

.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  @extend .flexCenter;
  flex-direction: column;
  z-index: 45;
  background-color: rgba($color: $shark, $alpha: 0.8);
  .wrapper {
    position: relative;
    width: calc(100% - 56px);
    padding: 28px;
    text-align: center;
    @extend .flexColumn;
    max-height: 90vh;
    background-color: $white;
    border-radius: $borderRadius;
    overflow: auto;

    .header {
      font-size: 28px;
      line-height: 34px;
      @include media-desktop {
        font-size: 48px;
        line-height: 56px;
      }
    }

    .text {
      p {
        font-size: 16px;
        line-height: 24px;

        @include media-desktop {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }

    @include media-desktop {
      width: 466px;
      padding: 75px 45px;
      text-align: left;
    }
  }

  .actions {
    padding-top: 28px;
    & > button:not(:last-child) {
      margin-bottom: 16px;
    }

    @include media-desktop {
      padding-top: 54px;
    }
  }
}
