html,
body {
  position: relative;
  margin: 0;
}

.overflow_unset {
  overflow: unset !important;
}
.strapline {
  text-transform: uppercase;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:global {
  .fade-enter .mainWrapper {
    opacity: 0.01;
  }

  .fade-enter-active .mainWrapper {
    opacity: 1;
    -webkit-transition: opacity 300ms ease-in;
    -moz-transition: opacity 300ms ease-in;
    -ms-transition: opacity 300ms ease-in;
    -o-transition: opacity 300ms ease-in;
    transition: opacity 300ms ease-in;
  }

  div.transition-group {
    position: relative;
  }
  .route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  .preamble {
    font-size: 18px !important;
    line-height: 27px !important;
    @include media-desktop {
      font-size: 20px !important;
      line-height: 27px !important;
    }
  }
}
