@import "style_utils";
.active {
  font-weight: bold;
  color: $red;
}

.submenu {
  font-weight: normal;
  list-style: none;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  @include transition([max-height visibility], 200ms, ease-in);
  &.open {
    visibility: visible;
    max-height: 80vh;
  }

  li {
    color: $boulder;
    font-weight: normal;
    display: inline-block;

    &.active,
    &:hover {
      color: $red;
      font-weight: bold;
    }
    a {
      position: relative;
      outline: none;
      font-size: 18px;
      line-height: 59px;
      color: $boulder;
      @include media-desktop {
        font-size: 16px;
        line-height: 59px;
      }
      &::after {
        content: "";
        outline: 1px dashed $red;
        position: absolute;
        border-radius: 10px;
        display: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @include media-desktop {
          top: 2px;
          left: -8px;
          width: calc(100% + 16px);
        }
      }

      &:focus-visible::after {
        display: block;
      }
    }
  }
}

.navItemWrapper {
  @extend .flexAlignCenter;
  width: 100%;
  .icon,
  .iconActive {
    margin-right: 10px;
  }
  button {
    &::after {
      content: "";
      outline: 1px dashed $red;
      position: absolute;
      top: 0;
      left: 5px;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      display: none;
    }

    &:focus-visible {
      outline: none;
      &::after {
        display: block;
      }
    }
  }
  .expandButton {
    margin-left: 10px;
    width: 6px;
    @include rotate(90deg);


    &.active {
      @include rotate(270deg);
    }
  }
}

.navItemWrapper > .navItem,
button > .navItem,
button.navItem {
  &::after {
    content: "";
    outline: 1px dashed $red;
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% + 10px);
    height: 100%;
    border-radius: 10px;
    display: none;
    @include media-desktop {
      top: 2px;
      left: -8px;
      width: calc(100% + 16px);
    }
  }

  &:focus-visible {
    outline: none;
    &::after {
      display: block;
    }
  }
}

.navItem {
  @extend .flexAlignCenter;
  position: relative;
  outline: none;

  .icon,
  .iconActive {
    margin-right: 10px;
  }

  &:hover {
    color: $red;
  }

  > .navItem {
    &::after {
      content: "";
      outline: 1px dashed $red;
      position: absolute;
      top: 1px;
      left: 1px;
      width: calc(100% + 10px);
      height: 100%;
      border-radius: 10px;
      display: none;
      @include media-desktop {
        top: 2px;
        left: -8px;
        width: calc(100% + 16px);
      }
    }
  }

  &:focus-visible {
    outline: none;
    .navItem::after {
      display: block;
    }
  }
}

.mainMenu {
  flex: 1;
  overflow: auto;
  max-height: 90%;
  @include media-desktop {
    max-height: 100%;
  }
  -webkit-overflow-scrolling: touch;
  &.separator {
    box-shadow: 0px 6px 9px rgba(153, 196, 212, 0.25);
  }
}

.itemName {
  position: relative;

  .badgeWrapper {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;

    .badge {
      position: absolute;
      font-size: 12px;
      line-height: 1;
      top: -19px;
      padding: 5px 8px;
      border-radius: 50%;
      background-color: red;
      color: white;
    }
  }
}

.langPickerWrapper {
  padding-bottom: 16px;
}
