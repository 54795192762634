@import "style_utils";

.attachment {
    @extend .flexRow;

    .buttonWithIcon {
      display: flex;
      align-items: center;
    
      > img {
        height: 16px;
        width: 16px;
      }

      .spinner {
        display: inline-block;
        position: static;
        width: 16px;
        height: 16px;
      }
      
      .attachmentButton {
        @include linkHoverEffect;

        border: 0;
        background: none;
        font-size: 16px;
        color: $red;

        &:focus-visible {
          outline: 1px dashed $red;
        }

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }      
      }
    } 
  }