@import "style_utils";

.promiseToPayWrapper {

  .contentWrapper {
    margin: 20px 0;
  }

  .buttonContainer {
    margin-top: 30px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  
    .button{
      margin-top: 10px;
      @include media-desktop {
        margin-top: 0;
        margin-right: 20px;
      }
    }

    @include media-desktop {
      flex-direction: row;
    }
  }
}

.paymentInfo {
  padding-left: 26px;
  padding-right: 24px;
  border-left: 2.4px solid $red;

  .row {
    display: flex;
    flex-direction: column;
    @include media-desktop {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    span {
      display: inline-block;
      font-size: 18px;
      line-height: 42px;
    }

    .label {
      margin-bottom: -11px;
      font-weight: bold;
      @include media-desktop {
        margin: 0;
      }
    }
  }
}