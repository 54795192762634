@import "style_utils";
.wrapper {
  @extend .flexCenter;
  flex-direction: column;
  width: 100%;
  opacity: 1;
  visibility: visible;
  @include transition((opacity, visibility), 0.2s, ease-out);
  &.hidden {
    opacity: 0;
    visibility: hidden;
    max-height: 0;
  }

  section.headerWrapper {
    padding-bottom: 42px;

    p {
      font-size: 18px;
    }
  }
}

.bold {
  font-weight: bold;
}

.headerWrapper {
  padding-top: 0;
  @include media-desktop {
    padding-top: 8px;
  }
}

.templateHeader {
  @extend .flexColumn;

  .image {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 0;
    padding-bottom: 62.5407166%;

    &.skeleton {
      div {
        & > span {
          padding-bottom: 62.5407166%;
        }
      }
    }
  }

  .templateContent {
    @extend .flexColumn;
    @extend .flexEnd;
    @extend .flex1;

    & > div {
      &:first-child {
        padding-bottom: 15px;
        border-bottom: 1px solid $botticelli;
      }

      h3 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 4px;
      }

      h4 {
        font-size: 20px;
        line-height: 30px;
      }

      &:not(.progressBar) {
        padding-top: 15px;
      }
    }

    .balance {
      @extend .flexRow;
      padding-top: 0 !important;

      div:first-child {
        flex: 1;
      }

      .image {
        max-width: 75px;
        padding-bottom: 50px;
      }
    }

    .templateTitleContainer {
      align-self: flex-start;

      @include media-desktop {
        white-space: nowrap;
      }

      .rightText {
        padding-left: 16px;
        text-align: right;
      }

      .interestInfoText {
        font-weight: normal;
        font-size: 16px;
        white-space: initial;
      }
    }

    .templateTitle {
      color: $boulder;
      line-height: 30px;
    }

    .progressBar {
      position: relative;
      width: 100%;
      height: 6px;
      border-radius: 15px;
      background-color: $borderGrey;

      & > div {
        position: absolute;
        width: 0;
        height: 100%;
        left: 0;
        border-radius: 15px;
        @include transition(width, 0.2s, ease-in);
        background-color: $red;
      }
    }
  }

  @include media-desktop {
    flex-direction: row;
    align-items: flex-start;
    .image {
      background-size: contain;
      background-position: bottom;
      width: 290px;
      height: 190px;
      padding-bottom: 0;

      @include media-ie {
        height: 200px;
      }
    }

    .templateContent {
      padding-left: 24px;
      padding-top: 0;
    }
  }
}

.outdatedBrowser {
  padding: 40px 28px;

  @include media-desktop {
    padding: 108px 145px;

    @include media-ie {
      width: calc(100% - 216px);
    }
  }
}

.notFound,
.outdatedBrowser {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .notFoundImage,
  .outdatedBrowserImage {
    position: relative;
    right: -28px;
    max-height: 500px;
    height: 100%;

    @include media-ie {
      max-height: none;
    }
  }

  @include media-desktop {
    justify-content: space-between;
    flex-direction: row;

    .notFoundContent,
    .notFoundImage,
    .outdatedBrowserContent,
    .outdatedBrowserImage {
      width: 50%;
    }

    .notFoundImage,
    .outdatedBrowserImage {
      right: 0;
      max-height: none;
    }
  }
}

.flexBetween {
  @extend .flexBetween;

  &.skeleton {
    margin-bottom: 12px;
  }
}

.link {
  color: $red;
  text-decoration: underline;
  font-weight: normal;
  cursor: pointer;
  padding-left: 5px;
}

.interestBuckets {
  margin-right: 10px;
  min-width: 210px;

  .rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  span {
    font-weight: normal;
    margin-bottom: 0;
    font-size: 16px;
  }
}

.bannerWrapper {
  padding-bottom: 24px;
}

.accountStatusContainer {
  div:not(:last-child) { 
    margin-bottom: 10px;
  }
}