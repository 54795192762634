@import "style_utils";

.wrapper {
  @extend .flexAlignCenter;
  cursor: pointer;

  img {
    margin-right: 9px;
  }
}
