@import "style_utils";

section.wrapper {
  width: 100%;
  &.accordion {
    padding: 0;
    @include media-xl {
      padding: 24px 0;
    }
  }

  .container {
    h3 {
      font-size: 20px;
      line-height: 28px;
    }
    p {
      margin-top: 15px;
    }
    li,
    p,
    a {
      font-size: 16px;
      line-height: 24px;
      &:not(:hover) {
        color: $black;
      }
    }
  }

  &.fullWidthNotification {
    padding-left: 0;
    padding-right: 0;
    @include media-desktop {
      padding: 20px 124px;
      &.inModal {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
