@import "style_utils";

.summaryWrapper {
  padding: 18px 24px;
  background-color: $milkPunch;

  .summaryItems {
    display: grid;
    gap: 24px;

    & > div {
      background-color: $butterMilk;
      display: flex;
      flex-direction: column;
      padding: 4px;

      & > span {
        line-height: 24px;
        &:first-child {
          font-weight: bold;
          min-height: 24px;
        }
      }
    }

    @include media-sm {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .summaryFooter {
    margin-top: 16px;
    p {
      font-size: 14px;
      margin: 0;

      &:not(:first-child) {
        margin-top: 24px;
      }
    }
  }
}

.spinner {
  position: relative;
  width: 16px;
  height: 16px;
}
