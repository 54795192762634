@import "style_utils";

.wrapper {
  position: relative;
  height: $buttonHeight_sm;
  font-size: 14px;
  line-height: 20px;
  border-radius: $borderRadius;
  padding: 4px 9px;
  background: none;
  border: none;
  cursor: pointer;
  @extend .inlineFlex;
  @extend .alignCenter;
  @include transition((border-color, border-width, color, background-color), 0.2s, ease-out);

  &,
  &:focus {
    border: 0;
    outline: none;
  }

  @include linkHoverEffect;

  &:focus-visible {
    outline: 1px dashed $red;
  }

  .successText {
    @include transition((opacity, visibility), 0.2s, ease-out);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    opacity: 0;
    visibility: hidden;
  }

  &.red {
    &:not(:hover) {
      background: $red;
      color: $white;
    }
    @include buttonHoverEffect;
  }

  &.black {
    background: $white;
    color: $black;
  }

  &.white {
    background: $white;
    color: $black;
    @include linkHoverEffect;
  }

  &.catskillWhite {
    background: $catskillWhite;
    color: $black;
  }

  &.boulder {
    background: $white;
    color: $boulder;
  }

  &.blue {
    background: $blackSqueeze;
    color: $black;
  }

  &.surfieGreen {
    background: $surfieGreen;
    color: $white;
  }

  &.mediumSky {
    background: $mediumSky;
    color: $black;
  }

  &.bordered {
    border: 2px solid $mercury;

    &.borderBlue,
    &.active {
      border-color: $sinbad;
    }

    &.borderBlack {
      border: 1px solid $black;
    }

    &.borderTundora {
      border: 1px solid $tundora;
    }

    &.blue {
      border: none;
    }

    &.grey {
      border: 1px solid $borderGrey;
    }

    &.boulder {
      border: 1px solid $boulder;
    }

    &.disabled {
      border: 1px solid $boulder;
    }

    &.borderDarkSky {
      border: 1px solid $darkSky;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.active {
    &.bordered {
      border-width: 3px;

      &.borderBlack {
        border-width: 2px;
      }
    }
  }

  &.large {
    padding: 4px 50px;
    width: 100%;
  }

  &.heightLarge {
    height: 52px;
    font-size: 16px;
    line-height: 28px;
  }

  &.success {
    background-color: $mediumSky;
    border-width: none;
    color: $black;
    cursor: initial;
    .successText {
      opacity: 1;
      visibility: visible;
    }
  }

  &.disabled {
    background-color: transparent;
    color: $boulder;
    cursor: default;
  }

  .iconSuffix {
    position: absolute;
    top: 0;
    right: 0;
    width: 19px;
    height: 19px;
  }

  @include media-desktop {
    height: $buttonHeight_lg;
    font-size: 16px;
    line-height: 23px;
    padding: 6px 12px;

    &.large {
      width: auto;
    }
  }
}

.loader {
  position: absolute;
  left: calc(50% - 10px);
  width: 20px;
  height: 20px;
  margin: 0;
  background: transparent;
  border-top: 2px solid $white;
  border-right: 2px solid transparent;
  border-radius: 50%;
  @include animation(spin 1s linear infinite);

  opacity: 0;
  visibility: hidden;
  @include transition((opacity, visibility), 0.2s, ease-out);

  &.red {
    border-color: $red;
  }

  &.loading {
    opacity: 1;
    visibility: visible;
  }
}

.content {
  width: 100%;
  @include transition((opacity, visibility), 0.2s, ease-out);
  opacity: 1;
  visibility: visible;
  position: relative;
  @extend .flexCenter;

  .inner {
    width: 100%;
    @extend .flexAlignCenter;

    span {
      width: 100%;
    }
  }

  &.spaceBetween .inner {
    justify-content: space-between;
  }

  &.loading,
  &.success > .inner {
    opacity: 0;
    visibility: hidden;
  }

  &.centered > div {
    justify-content: center;
  }
  &.justifyStart .inner {
    justify-content: start;
  }
}

.children {
  &.loading {
    opacity: 0;
    visibility: hidden;
  }
}
