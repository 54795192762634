@import "style_utils";

.toggle {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 32px;
  outline: none;
  margin-bottom: 0;
}
.toggle input {
  visibility: hidden;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $mercury;

  border-radius: 36px;
  @include transition(background-color, 400ms, ease);
}
.slider .icon {
  position: absolute;
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 3px;
  background-color: $silver;
  @include transition((background-color, transform), 400ms, ease);
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $red;

  .icon {
    @include transform(translate3d(22px, 0, 0));
    background-color: white;
  }
}

@include media-desktop {
  .toggle {
    width: 70px;
    height: 40px;
  }

  .slider .icon {
    height: 32px;
    width: 32px;
    bottom: 4px;
  }

  input:checked + .slider {
    .icon {
      @include transform(translate3d(28px, 0, 0));
    }
  }
}
