$headerHeight_lg: 82px;
$sideNav_lg: 325px;
$sideNav_xl: 415px;
$headerPaddingLeft_lg: 90px;
$headerPaddingRight_lg: 30px;
$headerPaddingLeft_xl: 150px;
$headerPaddingRight_xl: 120px;
$headerPadding_lg: 0 $headerPaddingRight_lg 0 $headerPaddingLeft_lg;
$headerPadding_xl: 0 $headerPaddingRight_xl 0 $headerPaddingLeft_xl;
$headerPadding_sm: 0 25px;
$buttonHeight_xl: 80px;
$buttonHeight_xl_mobile: 72px;
$buttonHeight_lg: 48px;
$buttonHeight_sm: 44px;
$footerHeight_lg: 418px;
$footerPaddingTop_lg: 80px;
$footerPaddingBottom_lg: 80px;
$lowerFooterHeight_lg: 75px;
$borderRadius: 8px;
