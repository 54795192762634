@import "style_utils";

.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  background-color: $white;
  @extend .flexCenter;
  flex-direction: column;
  @include transition((opacity, visibility), 0.2s, ease-out);

  &.active {
    visibility: visible;
    opacity: 1;
    z-index: 60;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.logoLoaded {
  animation: hide 0.1s linear forwards;
}

.loader {
  position: relative;
  top: -100px;
  opacity: 0;
  visibility: hidden;
  @include transition((opacity, visibility), 0.2s, ease-out);

  &.active {
    opacity: 1;
    visibility: visible;
  }
}
