@import "style_utils";

.wrapper {
  padding: 20px 0;

  .header {
    background: none;
    color: inherit;
    border: none;
    outline: inherit;
    width: 100%;
    text-align: left;
    padding: 0;
    font-size: 16px;
    margin-bottom: 0;
    @include transition((color, margin-bottom), 0.2s, ease-out);
    max-width: calc(100% - 24px);
    position: relative;
    cursor: pointer;

    &:focus-visible {
      outline: 1px dashed $red;
      border-radius: 5px;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      height: 14px;
      width: 2.3px;
      right: -15px;
      top: 50%;
      background-color: $red;
      @include transition((opacity), 0.2s, ease-out);
      opacity: 1;
    }
    &:before {
      @include rotate(90deg);
      top: calc(50% - 7px);
    }

    &:after {
      @include transform(translateY(-50%));
    }

    @include media-desktop {
      font-size: 18px;
      &:hover {
        color: $red;
      }
    }

    .buttonContent{
      display: flex;
      justify-content: space-between;
    }
  }

  .text {
    font-size: 16px;
    line-height: 23px;
    border-left: 2.4px solid $red;
    padding-left: 25px;
    max-height: 0px;
    max-width: calc(100% - 30px);
    overflow: hidden;
  }

  .childWrapper {
    visibility: hidden;
  }

  &.open {
    .header {
      font-weight: bold;
      margin-bottom: 25px;

      &:after {
        opacity: 0;
      }
    }

    .text {
      max-height: none;
      .childWrapper {
        visibility: visible;
      }
    }
  }

  &.borderedBottom {
    border-bottom: 1px solid $borderGrey;
  }

}
