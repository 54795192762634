@import "style_utils";
$padding_mobile: 0 34px;
$padding_desktop: 0 38px;
$collapse_duration: 0.3s;
$expand_duration: 1s;

.wrapper {
  background: $white;
  box-shadow: 0px 6px 9px rgba(153, 196, 212, 0.5);
  border-radius: $borderRadius;

  &:not(:last-child) {
    margin-bottom: 38px;
  }

  .header {
    padding: $padding_mobile;
    height: 78px;
    min-height: 78px;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $borderGrey;
    cursor: pointer;
    background: none;
    color: inherit;
    outline: inherit;
    width: 100%;
    text-align: left;
    &:focus-visible {
      outline: 1px dashed $red;
    }
    @extend .flexBetween;

    img {
      @include transition((transform), 0.2s, ease-out);
    }

    @include media-desktop {
      padding: $padding_desktop;
    }
  }

  .content {
    position: relative;
    max-height: 0px;
    overflow: hidden;
    @include transition(
      (max-height),
      $collapse_duration,
      cubic-bezier(0, 1, 0, 1)
    ); // Bezier curve to nicely collapse the accordion with the max-height hack
    padding: 0;

    &:not(.fullWidth) {
      padding: $padding_mobile;
    }

    @include media-desktop {
      &:not(.fullWidth) {
        padding: $padding_desktop;
      }
    }

    .contentWrapper {
      visibility: hidden;
      &.hide {
        // Animation prevents content to dissapear before accordion collapses
        animation: $collapse_duration delayHide forwards;
      }
    }
  }

  &.open {
    .header {
      img {
        @include rotate(180deg);
      }
    }

    .content {
      // Note: Max-height is set in the component
      @include transition((max-height), $expand_duration, ease-in);
      overflow: visible;
      &.expanding {
        // hide overflowing content during expand animation
        animation: $expand_duration delayShowOverflow;
      }

      .contentWrapper {
        visibility: visible;
      }
    }
  }
}

.boulder {
  color: $boulder;
}

.verticalPadding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.accountSummary {
  min-height: 80px;
  display: flex;
  align-items: center;
  position: relative;

  span:last-child {
    text-align: right;
  }
}

.notification {
  width: 100%;
  border-radius: 8px;
  padding: 12px 18px;
  margin-bottom: 10px;
  p {
    margin-bottom: 0;
  }
  &.error {
    background-color: $lightError;
  }
  &.warning {
    background-color: $lightWarning;
  }
}

.notificationContainer {
  width: 100%;
}