@import "style_utils";

.wrapper {
  width: 100%;
  position: absolute;
}

.notification {
  max-height: 100%;
  position: relative;
  z-index: 10;
  padding-left: 0px;
  padding-right: 0px;
  .wrapper {
    position: relative;
    width: 100%;
    padding: 20px;
    margin-bottom: 3px;

    @extend .flexCol;
    @extend .flexStart;
  }

  @include media-desktop {
    .wrapper {
      flex-direction: row;
      box-shadow: 0px 6px 9px rgba(153, 196, 212, 0.25);
      padding: 20px 100px;
    }
  }

  &.information {
    .wrapper {
      background-color: $blackSqueeze;
    }
  }

  &.important {
    .wrapper {
      background-color: $milkPunch;
    }
  }

  &.error {
    .wrapper {
      background-color: $lightError;
    }
  }

  .icon {
    padding-right: 16px;
    position: relative;
  }

  .innerWrapper {
    position: relative;
    width: 100%;
    max-width: 750px;
  }

  .contentWrapper {
    position: relative;
    max-width: 90%;
    padding-top: 12px;
    .header {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
    }

    .link {
      display: block;
      margin-top: 16px;
    }

    @include media-desktop {
      padding-top: 0;
      max-width: 525px;
    }
  }
}
