@import "style_utils";

.wrapper {
  padding: 20px 0;
  overflow: hidden;

  .label {
    font-size: 16px;
    padding-bottom: 0;
    @include transition((color, padding-bottom), 0.2s, ease-out);
    position: relative;
    cursor: pointer;
    @include removeNativeButtonStyling;

    text-align: left;
    width: 100%;

    &:focus-visible .labelText {
      outline: 1px dashed $red;
      border-radius: 3px;
    }

    .header {
      display: flex;
    }

    .labelText {
      width: 97%;
      text-align: left;
      display: block;
      &:nth-child(2) {
        text-align: right;
        margin-right: 22px;
      }
    }
    .icon {
      position: absolute;
      top: 15px;
      right: 0;
      &:before,
      &:after {
        content: "";
        position: absolute;
        height: 14px;
        width: 2.3px;
        right: 10px;
        top: 50%;
        background-color: $red;
        @include transition((opacity), 0.2s, ease-out);
        opacity: 1;
      }
      &:before {
        @include rotate(90deg);
        top: calc(50% - 7px);
      }

      &:after {
        @include transform(translateY(-50%));
      }
    }

    @include media-desktop {
      font-size: 18px;
      &:hover {
        color: $red;
      }
    }
  }

  &.open {
    .label {
      font-weight: bold;
      color: $black;
      padding-bottom: 25px;
      .icon:after {
        opacity: 0;
      }
      @include media-desktop {
        &:hover {
          color: $red;
        }
      }
    }
  }

  .content {
    position: relative;
    width: 100%;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    @include transition((opacity, visibility), 0.2s, ease-out);
    font-size: 16px;
    line-height: 23px;

    .data {
      border-left: 2.4px solid $red;
      padding-left: 26px;
      &.hasChildren {
        margin-bottom: 24px;
      }
    }

    .contentRow {
      @extend .flexColumn;
      justify-content: space-between;
      padding-bottom: 10px;
      padding-right: 10px;
      max-height: 0;
      span {
        font-size: 16px;
        line-height: inherit;

        &.bold {
          font-weight: bold;
        }

        &:last-child {
          text-align: left;
        }
      }

      @include media-desktop {
        flex-direction: row;
        padding-bottom: 0;

        span {
          font-size: 18px;
          line-height: 42px;

          &:last-child {
            text-align: right;
          }
        }
      }
    }

    &.open {
      opacity: 1;
      visibility: visible;
      max-height: 100%;

      .contentRow {
        max-height: 100px;
      }
    }
  }

  .link {
    color: $red;
    text-decoration: underline;
    font-weight: normal;
    cursor: pointer;
    padding-left: 5px;
    @include linkHoverEffect;
  }
}

.contentTextarea {
  span {
    font-size: 16px;
    line-height: 24px;
  }
}
