@import "style_utils";

.wrapper {
  p:first-child,
  p:last-child {
    margin-bottom: 0;
  }

  .button {
    margin-top: 32px;
  }

  &.hasBackground {
    box-shadow: 0px 6px 9px rgba(153, 196, 212, 0.25);
    padding: 20px 32px;
  }

  &.information {
    background-color: $milkPunch;
  }

  &.important {
    background-color: $blackSqueeze;
  }

  &.error {
    background-color: $lightError;
  }

  &.success {
    background-color: $lightSuccess;
  }

  @include media-desktop {
    display: flex;
    align-items: baseline;

    &.hasBackground {
      padding: 18px 22px;
    }

    .button {
      margin-top: 18px;
    }

    img {
      position: relative;
      top: 7px;
      padding-right: 16px;
    }
  }
}
